import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";



export const getAllPosUserAction = createAsyncThunk(
  "PosUser/GetAllPosUser",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {
        ...data.filter
      },
      restaurantID: data.id,
    };

    return apiCall("/posuser/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                ...response.data.data
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdatePosUserAction = createAsyncThunk(
  "PosUser/insertupdateposUser",
  async (data) => {

    return apiCall("/posuser/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            // console.log("message",message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPOSUserAction = createAsyncThunk(
  "PosUser/GetPosUser",
  async (data) => {
    return apiCall("/posuser/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const posUserChangeStatusACTION = createAsyncThunk(
  "PosUser/changeStatus",
  async (data) => {
    return apiCall("/posuser/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const posUserDeleteACTION = createAsyncThunk(
  "PosUser/delete",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/posuser/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getPOSUserGoogleReviewQRcode = createAsyncThunk(
  "PosUser/getPOSUserGoogleReviewQRcode",
  async (data) => {
    return apiCall("/posuser/getgooglereviewqrcode", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);




