import { createSlice } from "@reduxjs/toolkit";
import { getAllConditionalVoucherAction, getConditionalVoucherAction, insertUpdateConditionalVoucherAction, conditionalVoucherChangeStatusACTION, ConditionalVoucherDeleteACTION } from "../../actions/discountPromotions/conditionalVoucherAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    conditionalVoucher: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedConditionalVoucher: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

};

const conditionalVoucherReducer = createSlice({
    name: "ConditionalVoucher",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },

        ResetConditionalVoucherDetailsState(state, action) {
            state.selectedConditionalVoucher = INITIAL_STATE.selectedConditionalVoucher;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
        },
    },



    extraReducers: (builder) => {

        // getAllConditionalVoucherAction start

        builder.addCase(getAllConditionalVoucherAction.fulfilled, (state, action) => {
            state.conditionalVoucher.data = action.payload.data;
            state.conditionalVoucher.apiMsg.status = action.meta.requestStatus;
            state.conditionalVoucher.apiMsg.message = "success";
        });
        builder.addCase(getAllConditionalVoucherAction.pending, (state, action) => {
            // console.log("getAllConditionalVoucherAction pending", action);
            state.conditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllConditionalVoucherAction.rejected, (state, action) => {
            // console.log("getAllConditionalVoucherAction rejected", action);
            state.conditionalVoucher.apiMsg.message = action.error.message;
            state.conditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });

        // getAllConditionalVoucherAction end

        // insertUpdateConditionalVoucherAction start

        builder.addCase(
            insertUpdateConditionalVoucherAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.conditionalVoucher.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.conditionalVoucher.data.records = updatedRecord;
                }
                else {
                    if (state.conditionalVoucher.data.records.length) {
                        state.conditionalVoucher.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.discount.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateConditionalVoucherAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateConditionalVoucherAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateConditionalVoucherAction end

        //------------------------------- getConditionalVoucherAction start -------------------------

        builder.addCase(getConditionalVoucherAction.fulfilled, (state, action) => {
            state.selectedConditionalVoucher.data = action.payload.data;
            state.selectedConditionalVoucher.apiMsg.status = action.meta.requestStatus;
            state.selectedConditionalVoucher.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getConditionalVoucherAction.pending, (state, action) => {
            state.selectedConditionalVoucher.data = null;
            state.selectedConditionalVoucher.apiMsg.message = action.meta.requestStatus;;
            state.selectedConditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getConditionalVoucherAction.rejected, (state, action) => {
            state.selectedConditionalVoucher.apiMsg.message = action.error.message;
            state.selectedConditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });


        //   getConditionalVoucherAction end

        // conditionalVoucherChangeStatusACTION start


        builder.addCase(conditionalVoucherChangeStatusACTION.fulfilled, (state, action) => {
            state.conditionalVoucher.data.records = state.conditionalVoucher.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(conditionalVoucherChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(conditionalVoucherChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // conditionalVoucherChangeStatusACTION end



        //------------------------------- ConditionalVoucherDeleteACTION start -------------------------

        builder.addCase(ConditionalVoucherDeleteACTION.fulfilled, (state, action) => {

            state.conditionalVoucher.data.records = state.conditionalVoucher.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(ConditionalVoucherDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(ConditionalVoucherDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- ConditionalVoucherDeleteACTION end -------------------------


    }

});

export const { RESET, ResetConditionalVoucherDetailsState } = conditionalVoucherReducer.actions;
export default conditionalVoucherReducer.reducer;
