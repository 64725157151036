import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  alert:{}
};

const siteReducer = createSlice({
  name: "Site",
  initialState: INITIAL_STATE,
  reducers: {
    ALERTaction(state, action) {
      state.alert = action.payload
    },

    RESET(state, action) {
      state.alert = {};
    },
  },
  extraReducers: (builder) => {
    
    
  },
});

export const { ALERTaction, RESET } = siteReducer.actions;
export default siteReducer.reducer;
