import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getAllUserShiftReportACTION } from "../../actions/UserShiftReportAction/userShiftReportAction";

const INITIAL_STATE = {
    userShiftReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const userShiftReportReducer = createSlice({
    name: "UserShiftReport",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {

        }
    },
    extraReducers: (builder) => {

        //------------------------------- getAllUserShiftReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllUserShiftReportACTION.fulfilled, (state, action) => {

            state.userShiftReport.data = action.payload.data;
            state.userShiftReport.apiMsg.status = action.meta.requestStatus;
            state.userShiftReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllUserShiftReportACTION.pending, (state, action) => {
                //console.log("getAllUserShiftReportACTION pending", action);
                state.userShiftReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllUserShiftReportACTION.rejected, (state, action) => {
                //console.log("getAllUserShiftReportACTION rejected", action);
                state.userShiftReport.apiMsg.message = action.error.message;
                state.userShiftReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllUserShiftReportACTION end -------------------------

    }
});

export const { RESET } = userShiftReportReducer.actions;
export default userShiftReportReducer.reducer;
