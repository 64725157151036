import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../API';
import * as Toast from "../../component/Toast";
import _ from 'lodash'
import { Enums } from '../../helper/constants/emuns-const';
export const getAllSalesSummaryReportACTION = createAsyncThunk(
    'Reports/getallsalessummary',
    async (data) => {
        return apiCall('/report/getallsalessummary', 'POST', data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {


                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });

    }
)

export const getAllSalesQuantityReportACTION = createAsyncThunk(
    'Reports/getallsalesquantity',
    async (data) => {
        return apiCall('/report/getallsalesquantity', 'POST', data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getAllVoidLineReportACTION = createAsyncThunk(
    'Reports/getallvoidline',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/posvoidline/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getAllVoidSaleReportACTION = createAsyncThunk(
    'Reports/getallvoidsale',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/posvoidsale/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getAllNoSaleReportACTION = createAsyncThunk(
    'Reports/getallnosale',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/posnosale/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getPayOutReportACTION = createAsyncThunk(
    'Reports/getallpayout',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
                payType: Enums.PayInOutReportType.PayOut,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/pospayinouttransaction/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getPayInReportACTION = createAsyncThunk(
    'Reports/getallpayin',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
                payType: Enums.PayInOutReportType.PayIn,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/pospayinouttransaction/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

// export const getCashInReportACTION = createAsyncThunk(
//     'Reports/getallcashin',
//     async (data) => {
//         let d = {
//             device: 1,
//             pageNo: -1,
//             pageSize: 0,
//             sortBy: 0,
//             sortOrder: 0,
//             filter: {
//                 restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
//                 posUserID: data.posUserID ? data.posUserID : null,
//                 fromDate: data.fromDate,
//                 toDate: data.toDate,
//                 cashType: Enums.ReportType.CashIn,
//             },
//             restaurantID: data.restaurantID,
//         };
//         return apiCall('/report/poscashinout/getAll', 'POST', d, false)
//             .then((response) => {
//                 if (response.status === 200) {
//                     if (!response.data.hasError) {
//                         return {
//                             ...response.data,
//                             data: {
//                                 ...response.data.data
//                             }
//                         };
//                     } else {
//                         Toast.error(response.data.message.message);
//                         return Promise.reject({
//                             ...response.data.message,
//                         });
//                     }
//                 }
//             })
//             .catch((error) => {
//                 Toast.error(error);
//                 return Promise.reject({
//                     ...error,
//                 });
//             });
//     }
// )

// export const getCashLiftReportACTION = createAsyncThunk(
//     'Reports/getallcashlift',
//     async (data) => {
//         let d = {
//             device: 1,
//             pageNo: -1,
//             pageSize: 0,
//             sortBy: 0,
//             sortOrder: 0,
//             filter: {
//                 restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
//                 posUserID: data.posUserID ? data.posUserID : null,
//                 fromDate: data.fromDate,
//                 toDate: data.toDate,
//                 cashType: Enums.ReportType.CashOut,
//             },
//             restaurantID: data.restaurantID,
//         };
//         return apiCall('/report/poscashinout/getAll', 'POST', d, false)
//             .then((response) => {
//                 if (response.status === 200) {
//                     if (!response.data.hasError) {
//                         return {
//                             ...response.data,
//                             data: {
//                                 ...response.data.data
//                             }
//                         };
//                     } else {
//                         Toast.error(response.data.message.message);
//                         return Promise.reject({
//                             ...response.data.message,
//                         });
//                     }
//                 }
//             })
//             .catch((error) => {
//                 Toast.error(error);
//                 return Promise.reject({
//                     ...error,
//                 });
//             });
//     }
// );

