import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  kitchenDisplayAddUpdateACTION,
  kitchenDisplayGetAllACTION,
  kitchenDisplayChangeStatusACTION,
  kitchenDisplayDeleteACTION,
  getKitchenDisplayACTION 
} from "../../actions/settings/kitchenDisplayActions";

const INITIAL_STATE = {
  kitchenDisplays: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
 kitchenDisplayDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedKitchenDisplayDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateKitchenDisplayDetails:{
    apiMsg:{
      message: null,
      status: null,
      type: null,
    },
  }
};

const kitchenDisplayReducer = createSlice({
  name: "KitchenDisplay",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetKitchenDisplayDetailsState(state, action) {
      state.kitchenDisplayDetails = INITIAL_STATE.kitchenDisplayDetails;
      state.insertUpdateKitchenDisplayDetails = INITIAL_STATE.insertUpdateKitchenDisplayDetails;
      state.selectedKitchenDisplayDetails=INITIAL_STATE.selectedKitchenDisplayDetails
    },
  },
  extraReducers: (builder) => {
    //------------------------------- kitchenDisplayAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(kitchenDisplayAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("kitchenDisplayAddUpdateACTION", action);

        //state.kitchenDisplays.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.kitchenDisplays.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.meta.arg;
            }
            else{
              return x;
            }
          })

          state.kitchenDisplays.data.records=updatedRecord;
        }
        else{
          if(state.kitchenDisplays.data.records.length){
            state.kitchenDisplays.data.records.unshift(action.payload.data.data);
          }
          else{
            state.kitchenDisplays.data.records.push(action.payload.data.data);
          }
        }

        state.insertUpdateKitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateKitchenDisplayDetails.apiMsg.message = action.payload.message.message;
    }),
    builder.addCase(kitchenDisplayAddUpdateACTION.pending, (state, action) => {
      state.insertUpdateKitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
    }),
    builder.addCase(kitchenDisplayAddUpdateACTION.rejected, (state, action) => {
      state.insertUpdateKitchenDisplayDetails.apiMsg.message = action.error.message;
      state.insertUpdateKitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
    }),

      //------------------------------- kitchenDisplayAddUpdateACTION start -------------------------

      //------------------------------- kitchenDisplayGetAllACTION start -------------------------

      builder.addCase(kitchenDisplayGetAllACTION.fulfilled, (state, action) => {
       // console.log("kitchenDisplayGetAllACTION", action);

        state.kitchenDisplays.data = action.payload.data;
        state.kitchenDisplays.apiMsg.status = action.meta.requestStatus;
        state.kitchenDisplays.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenDisplayGetAllACTION.pending, (state, action) => {
        //console.log("kitchenDisplayGetAllACTION pending", action);
        state.kitchenDisplays.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenDisplayGetAllACTION.rejected, (state, action) => {
        //console.log("kitchenDisplayGetAllACTION rejected", action);
        state.kitchenDisplays.apiMsg.message = action.error.message;
        state.kitchenDisplays.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- kitchenDisplayGetAllACTION end -------------------------

    //------------------------------- kitchenDisplayChangeStatusACTION start -------------------------

    builder.addCase(kitchenDisplayChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("kitchenDisplayChangeStatusACTION", action);

        state.kitchenDisplays.data.records = state.kitchenDisplays.data.records.map((item)=>{

          return {
              ...item,
              active:action.payload.id === item.id ? !item.active : item.active 
          }
      })
        state.kitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
        state.kitchenDisplayDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenDisplayChangeStatusACTION.pending, (state, action) => {
        //console.log("kitchenDisplayChangeStatusACTION pending", action);
        state.kitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenDisplayChangeStatusACTION.rejected, (state, action) => {
        //console.log("kitchenDisplayChangeStatusACTION rejected", action);
        state.kitchenDisplayDetails.apiMsg.message = action.error.message;
        state.kitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- kitchenDisplayChangeStatusACTION end -------------------------

     //------------------------------- kitchenDisplayDeleteACTION start -------------------------
    
  builder.addCase(kitchenDisplayDeleteACTION.fulfilled, (state, action) => {
  //console.log("kitchenDisplayDeleteACTION", action);

  state.kitchenDisplays.data.records = state.kitchenDisplays.data.records.filter((item,index)=>item.id !== action.payload.data.id)
  
  state.kitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
  state.kitchenDisplayDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(kitchenDisplayDeleteACTION.pending, (state, action) => {
    //console.log("kitchenDisplayDeleteACTION pending", action);
    state.kitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(kitchenDisplayDeleteACTION.rejected, (state, action) => {
    //console.log("kitchenDisplayDeleteACTION rejected", action);
    state.kitchenDisplayDetails.apiMsg.message = action.error.message;
    state.kitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
  }),
  //------------------------------- kitchenDisplayDeleteACTION end -------------------------

  //------------------------------- getKitchenDisplayACTION start -------------------------

  builder.addCase(getKitchenDisplayACTION.fulfilled, (state, action) => {
    //console.log("getKitchenDisplayACTION", action);
    state.selectedKitchenDisplayDetails.data = action.payload.data;
    state.selectedKitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
    state.selectedKitchenDisplayDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getKitchenDisplayACTION.pending, (state, action) => {
    //console.log("getKitchenDisplayACTION pending", action);
    state.selectedKitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getKitchenDisplayACTION.rejected, (state, action) => {
    //console.log("getKitchenDisplayACTION rejected", action);
    state.selectedKitchenDisplayDetails.apiMsg.message = action.error.message;
    state.selectedKitchenDisplayDetails.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getKitchenDisplayACTION end -------------------------
    
  },
});

export const { RESET,ResetKitchenDisplayDetailsState } = kitchenDisplayReducer.actions;
export default kitchenDisplayReducer.reducer;
