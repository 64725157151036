import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../../API';
import * as Toast from "../../../component/Toast";
import _ from 'lodash'
import { Enums } from '../../../helper/constants/emuns-const';


export const getAllCashInReportACTION = createAsyncThunk(
    'CashInReports/getallcashin',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
                cashType: Enums.CashReportType.CashIn,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/poscashinout/getAll', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getCashInReportAction = createAsyncThunk(
    "CashInReports/GetCashIn",
    async (data) => {
        return apiCall("/report/poscashinout/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const updateCashInAction = createAsyncThunk(
    "CashInReports/UpdateCashIn",
    async (data) => {

        return apiCall("/report/poscashinout/update", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const CashInDeleteACTION = createAsyncThunk(
    "CashInReports/DeleteCashIn",
    async (data) => {
      //console.logog("data2 ", data);
      return apiCall("/report/poscashinout/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              Toast.success(response.data.message.message);
              return {
                ...response.data,
                data: {
                  ...data,
                },
              };
            } else {
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
