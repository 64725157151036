import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  scheduleDayAddUpdateACTION,
  getScheduleDayACTION,
  scheduleDayGetAllACTION
} from "../actions/scheduleDayActions";
import _ from "lodash";

const INITIAL_STATE = {
  scheduleDays: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  scheduleSpecificDayDetails: {
    data: {
      days: []
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  scheduleDayDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateScheduleDayDetails: {
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const scheduleDayReducer = createSlice({
  name: "ScheduleDay",
  initialState: INITIAL_STATE,
  reducers: {
     UPDATESWITCH24H(state, action) {
      if (action.payload.typeDay === "ALLDAY") {
        state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].is24Hour = action.payload.is24Hour;
      } else if (action.payload.typeDay === "DAY") {
        state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].is24Hour = action.payload.is24Hour;
      }
    },
    UPDATESWITCHOPEN(state, action) {
      if (action.payload.typeDay === "ALLDAY") {
        state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].opened = action.payload.opened;
      } else if (action.payload.typeDay === "DAY") {
        state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].opened = action.payload.opened;
      }
    
    },
    ADDNEWDAY(state, action) {
      state.scheduleSpecificDayDetails.data.days.push(action.payload)
    },
    UPDATEDAY(state, action) {
    
      if (action.payload.typeDate === 'FROMDATE'){
        state.scheduleSpecificDayDetails.data.days[action.payload.indexID].fromDate = action.payload.date;
      }else if(action.payload.typeDate === 'TODATE'){
        state.scheduleSpecificDayDetails.data.days[action.payload.indexID].toDate = action.payload.date;
      }
      state.scheduleSpecificDayDetails.data.days[action.payload.indexID].day = action.payload.day;
    },
    ADDNEWSCHEDULE(state, action) {
      if (action.payload.typeDay === 'ALLDAY') {
        state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].scheduleDays.push(action.payload.data)
      } else if (action.payload.typeDay === 'DAY') {
        state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].scheduleDays.push(action.payload.data)
      }
    },
    UPDATESCHEDULE(state, action) {
      if (action.payload.typeDay === 'ALLDAY') {
        if (action.payload.type === 'FROM') {
          state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].scheduleDays[action.payload.indexID].fromTime = action.payload.fromTime
        } else if (action.payload.type === 'TO') {
          state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].scheduleDays[action.payload.indexID].toTime = action.payload.toTime
        }
      } else if (action.payload.typeDay === 'DAY') {
        if (action.payload.type === 'FROM') {
          state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].scheduleDays[action.payload.indexID].fromTime = action.payload.fromTime
        } else if (action.payload.type === 'TO') {
          state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].scheduleDays[action.payload.indexID].toTime = action.payload.toTime
        }
      }
    },
    REMOVESCHEDULE(state, action) {
      if (action.payload.typeDay == "ALLDAY") {
        state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].scheduleDays = _.remove(state.scheduleDayDetails.data.schedules.allDays[action.payload.mainIndex].scheduleDays, (n, i) => {
          return i !== action.payload.indexID;
        });
      } else if (action.payload.typeDay == "DAY") {
        state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].scheduleDays = _.remove(state.scheduleSpecificDayDetails.data.days[action.payload.mainIndex].scheduleDays, (n, i) => {
          return i !== action.payload.indexID;
        });
      }
    },
    REMOVEDAY(state, action) {
      state.scheduleSpecificDayDetails.data.days = state.scheduleSpecificDayDetails.data.days.filter((item, i) => i !== action.payload.indexID)
    },
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetScheduleDayDetailsState(state, action) {
      state.scheduleDayDetails = INITIAL_STATE.scheduleDayDetails;
      state.scheduleSpecificDayDetails = INITIAL_STATE.scheduleSpecificDayDetails;
      state.insertUpdateScheduleDayDetails = INITIAL_STATE.insertUpdateScheduleDayDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- scheduleDayAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(scheduleDayAddUpdateACTION.fulfilled, (state, action) => {
      state.insertUpdateScheduleDayDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateScheduleDayDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(scheduleDayAddUpdateACTION.pending, (state, action) => {
        state.insertUpdateScheduleDayDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(scheduleDayAddUpdateACTION.rejected, (state, action) => {
        state.insertUpdateScheduleDayDetails.apiMsg.message = action.error.message;
        state.insertUpdateScheduleDayDetails.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- scheduleDayAddUpdateACTION start -------------------------

      //------------------------------- scheduleDayGetAllACTION start -------------------------

      builder.addCase(scheduleDayGetAllACTION.fulfilled, (state, action) => {
        state.scheduleDays.data = action.payload.data;
        state.scheduleDays.apiMsg.status = action.meta.requestStatus;
        state.scheduleDays.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(scheduleDayGetAllACTION.pending, (state, action) => {
        state.scheduleDays.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(scheduleDayGetAllACTION.rejected, (state, action) => {
        state.scheduleDays.apiMsg.message = action.error.message;
        state.scheduleDays.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- scheduleDayGetAllACTION end -------------------------

      //------------------------------- getScheduleDayACTION start -------------------------
      builder.addCase(getScheduleDayACTION.fulfilled, (state, action) => {
        state.scheduleDayDetails.data = action.payload.data;
        state.scheduleSpecificDayDetails.data.days = action.payload.data.schedules.days;
        state.scheduleDayDetails.apiMsg.status = action.meta.requestStatus;
        state.scheduleDayDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getScheduleDayACTION.pending, (state, action) => {
        state.scheduleDayDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getScheduleDayACTION.rejected, (state, action) => {
        state.scheduleDayDetails.apiMsg.message = action.error.message;
        state.scheduleDayDetails.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- getScheduleDayACTION end ------------------------- 

  },
});

export const { UPDATESWITCH24H,UPDATESWITCHOPEN, ADDNEWDAY, UPDATEDAY, ADDNEWSCHEDULE, UPDATESCHEDULE, REMOVEDAY, REMOVESCHEDULE, RESET, ResetScheduleDayDetailsState } = scheduleDayReducer.actions;
export default scheduleDayReducer.reducer;
