import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  getLookupsACTION
} from "../actions/lookupActions";

const INITIAL_STATE = {
  lookups: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const lookupsReducer = createSlice({
  name: "lookups",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetLookupDetailsState(state, action) {
      
    },
  },
  extraReducers: (builder) => {

    
  //------------------------------- getLookupsACTION start -------------------------
// eslint-disable-next-line
  builder.addCase(getLookupsACTION.fulfilled, (state, action) => {
    //console.log("getLookupsACTION", action);
    state.lookups.data = action.payload.data;
    state.lookups.apiMsg.status = action.meta.requestStatus;
    state.lookups.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getLookupsACTION.pending, (state, action) => {
    //console.log("getLookupsACTION pending", action);
    state.lookups.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getLookupsACTION.rejected, (state, action) => {
    //console.log("getLookupsACTION rejected", action);
    state.lookups.apiMsg.message = action.error.message;
    state.lookups.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getVariantACTION end -------------------------
    
  },
});

export const { RESET,ResetLookupDetailsState } = lookupsReducer.actions;
export default lookupsReducer.reducer;
