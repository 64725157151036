import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast"
import {Enums} from '../../../helper/constants/emuns-const';


export const categoriesMasterGetallACTION = createAsyncThunk(
    "Categories/categoriesMasterGetall",
    async (data) => {
      let d = {
        device: 1,
        pageNo: -1,
        pageSize: 0,
        sortBy: 0,
        sortOrder: 0,
        filter:{
          ...data.filter
        },
        restaurantID: data.restaurantID,
      };
    
      return apiCall("/categorymaster/getall", "POST", d, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              ////console.log("response.data ", response.data);
              return {
                ...response.data,
                
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const parentcategoriesMasterGetallACTION = createAsyncThunk(
    "Categories/parentcategoriesMasterGetall",
    async (data) => {
      let d = {
        device: 1,
        pageNo: -1,
        pageSize: 0,
        sortBy: 0,
        sortOrder: 0,
        filter:{
          parentCategoryID:null,
          active:true
        },
        restaurantID: data,
      };
  
      return apiCall("/categorymaster/getall", "POST", d, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              ////console.log("response.data ", response.data);
              return {
                ...response.data,
                
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
  export const addUpdateACTION = createAsyncThunk(
    "Categories/addUpdate",
    async (data) => {
      //console.log("data2 ", data);
      return apiCall("/categorymaster/insertupdate", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              Toast.success(response.data.message.message)
              return {
                data:{
                  id: response.data.data,
                 ...response.data,
               },
               message:{
                 code:response.data.message.code,
                 type:response.data.message.type,
                 message:response.data.message.message
               },
               newRecord:data.id ? false : true
               }
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message)
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error)
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const getCategoriesACTION = createAsyncThunk(
    "Categories/getCategories",
    async (data) => {
      return apiCall("/categorymaster/get", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              
              return {
                ...response.data,
               
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const changeStatusACTION = createAsyncThunk(
    "Categories/changeStatus",
    async (data) => {
      return apiCall("/categorymaster/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              if(data.statusType!==Enums.ChangeStatus.SortOrder){
                Toast.success(response.data.message.message)
              }
             
              return {
                ...response.data,
                id: data.id,
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message)
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error)
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const deleteACTION = createAsyncThunk(
    "Categories/delete",
    async (data) => {
      //console.log("data2 ", data);
      return apiCall("/categorymaster/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              Toast.success(response.data.message.message);
              return {
                ...response.data,
                data: {
                  ...data,
                },
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );