import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
    getWebSettingsACTION,
    webSettingsAddUpdateACTION
} from '../actions/webSettings.Actions'
import _ from "lodash";

const INITIAL_STATE = {
    webSettings: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatewebSettings: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }


};

const webSettingsReducer = createSlice({
    name: "WebSetting",
    initialState: INITIAL_STATE,
    reducers: {
        RESETWEBSETTING(state, action) {
            state.webSettings = INITIAL_STATE.webSettings
            state.insertUpdatewebSettings = INITIAL_STATE.insertUpdatewebSettings
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getWebSettingsACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getWebSettingsACTION.fulfilled, (state, action) => {

            state.webSettings.data = action.payload.data;
            state.webSettings.apiMsg.status = action.meta.requestStatus;
            state.webSettings.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getWebSettingsACTION.pending, (state, action) => {

                state.webSettings.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getWebSettingsACTION.rejected, (state, action) => {

                state.webSettings.apiMsg.message = action.error.message;
                state.webSettings.apiMsg.status = action.meta.requestStatus;
            }),
        //------------------------------- getWebSettingsACTION end -------------------------
        //------------------------------- webSettingsAddUpdateACTION start -------------------------
        builder.addCase(webSettingsAddUpdateACTION.fulfilled, (state, action) => {

          
            state.insertUpdatewebSettings.apiMsg.status = action.meta.requestStatus;
            state.insertUpdatewebSettings.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(webSettingsAddUpdateACTION.pending, (state, action) => {

                state.insertUpdatewebSettings.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(webSettingsAddUpdateACTION.rejected, (state, action) => {

                state.insertUpdatewebSettings.apiMsg.message = action.error.message;
                state.insertUpdatewebSettings.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- webSettingsAddUpdateACTION end -------------------------

    }
});

export const { RESETWEBSETTING } = webSettingsReducer.actions;
export default webSettingsReducer.reducer;
