import React from "react";
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
export function Alert(props) {
  const state = useSelector(state => state.siteReducer)
  const onClick = ()=>{

    state.alert.setModal()
  }
  if (state.alert.modal !== undefined) {

    return (
      <Modal
      show={ state.alert.modal}
      onHide={()=>{state.alert.setModal(!state.alert.modal)}}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${state.alert.title}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          state.alert.children
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClick}>
          {state.alert.secondaryBtntitle}
        </Button>
        <Button variant="secondary" onClick={state.alert.primaryOnclick}>
          {state.alert.primaryBtntitle}
          
        </Button>
      </Modal.Footer>
    </Modal>
  );
  }
}

export function CustomModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
