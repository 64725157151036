import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";
import {Enums} from '../../../helper/constants/emuns-const';

export const variantAddUpdateACTION = createAsyncThunk(
  "variant/addUpdate",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/variantmaster/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const variantGetAllACTION = createAsyncThunk(
  "variant/GetAll",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {
        ...data.filter
      },
      restaurantID: data.id,
    };

    return apiCall("/variantmaster/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                ...response.data.data
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const variantChangeStatusACTION = createAsyncThunk(
  "variant/changeStatus",
  async (data) => {
    return apiCall("/variantmaster/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            if (data.statusType !== Enums.ChangeStatus.SortOrder) {
              Toast.success(response.data.message.message)
            }
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const variantDeleteACTION = createAsyncThunk(
  "variant/delete",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/variantmaster/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getVariantACTION = createAsyncThunk(
  "variant/get",
  async (data) => {
    return apiCall("/variantmaster/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
