import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";
import * as Toast from "../../component/Toast";

export const paymentConfigurationAddUpdateACTION = createAsyncThunk(
  "PaymentConfiguration/addUpdate",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/paymentconfiguration/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
            
                ...response.data,
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const  getPaymentConfigurationACTION = createAsyncThunk(
  "PaymentConfiguration/Get",
  async (data) => {
    return apiCall("/paymentconfiguration/getall", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,

              };
            } else {
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
