import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";
import { Item } from "devextreme-react/data-grid";

export const servicezonGetallACTION = createAsyncThunk(
    "Servicezone/servicezonGetall",
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: { ...data.filter },
            restaurantID: data.restaurantID,
        };
        console.log('data ',data)
        return apiCall("/servicezone/getall", "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            },
                        };
                    } else {
                        //console.log("response error", response.data.message);
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getservicezoneACTION = createAsyncThunk(
    "Servicezone/getservicezone",
    async (data) => {
      return apiCall("/servicezone/get", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              
              return {
                ...response.data,
               
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  export const addupdateACTION = createAsyncThunk(
    "Servicezone/addupdate",
    async (data) => {
      //console.log("data2 ", data);
      return apiCall("/servicezone/insertupdate", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              ////console.log("response.data ", response.data);
              Toast.success(response.data.message.message);
              return {
                data:{
                 ...response.data,
               },
               message:{
                 code:response.data.message.code,
                 type:response.data.message.type,
                 message:response.data.message.message
               },
               newRecord:data.id ? false : true
               }
            } else {
              ////console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  ); 

  export const changeStatusACTION = createAsyncThunk(
    "Servicezone/changeStatus",
    async (data) => {
      return apiCall("/servicezone/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              Toast.success(response.data.message.message);
              return {
                ...response.data,
                id: data.id,
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
  export const deleteACTION = createAsyncThunk(
    "Servicezone/delete",
    async (data) => {
      //console.log("data2 ", data);
      return apiCall("/servicezone/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log("response.data ", response.data);
              Toast.success(response.data.message.message);
              return {
                ...response.data,
                data: {
                  ...data,
                },
              };
            } else {
              //console.log("response error", response.data.message);
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
  export const getAllwithCoordinatesACTION = createAsyncThunk(
    "Servicezone/getAllwithCoordinates",
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: { ...data.filter },
            restaurantID: data.restaurantID,
        };
        console.log('data ',data)
        return apiCall("/servicezone/getallwithcoordinates", "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            },
                        };
                    } else {
                        //console.log("response error", response.data.message);
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);