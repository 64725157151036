import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  taxSettingAddUpdateACTION,
  taxSettingGetAllACTION,
  taxSettingChangeStatusACTION,
  taxSettingDeleteACTION,
  getTaxSettingACTION
} from "../../actions/taxSettingActions";
import { Enums } from "../../../helper/constants/emuns-const";

const INITIAL_STATE = {
  taxSettings: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  taxSettingDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedTaxSettingDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateTaxSettingDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const taxSettingReducer = createSlice({
  name: "TaxSetting",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetTaxSettingDetailsState(state, action) {
      state.taxSettingDetails = INITIAL_STATE.taxSettingDetails;
      state.insertUpdateTaxSettingDetails = INITIAL_STATE.insertUpdateTaxSettingDetails;
      state.selectedTaxSettingDetails = INITIAL_STATE.selectedTaxSettingDetails;
    },
  },
  extraReducers: (builder) => {

    //------------------------------- taxSettingAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(taxSettingAddUpdateACTION.fulfilled, (state, action) => {
      //console.log("taxSettingAddUpdateACTION", action);

      //state.taxSettings.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.taxSettings.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            return action.payload.data.data;
          }
          else {
            return x;
          }
        })

        state.taxSettings.data.records = updatedRecord;
      }
      else {
        if (state.taxSettings.data.records.length) {
          state.taxSettings.data.records.unshift(action.payload.data.data);
        }
        else {
          state.taxSettings.data.records.push(action.payload.data.data);
        }
      }

      state.insertUpdateTaxSettingDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateTaxSettingDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(taxSettingAddUpdateACTION.pending, (state, action) => {
        state.insertUpdateTaxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(taxSettingAddUpdateACTION.rejected, (state, action) => {
        state.insertUpdateTaxSettingDetails.apiMsg.message = action.error.message;
        state.insertUpdateTaxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- taxSettingAddUpdateACTION end -------------------------

      //------------------------------- taxSettingGetAllACTION start -------------------------

      builder.addCase(taxSettingGetAllACTION.fulfilled, (state, action) => {
        //console.log("taxSettingGetAllACTION", action);

        state.taxSettings.data = action.payload.data;
        state.taxSettings.apiMsg.status = action.meta.requestStatus;
        state.taxSettings.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(taxSettingGetAllACTION.pending, (state, action) => {
        //console.log("taxSettingGetAllACTION pending", action);
        state.taxSettings.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(taxSettingGetAllACTION.rejected, (state, action) => {
        // console.log("taxSettingGetAllACTION rejected", action);
        state.taxSettings.apiMsg.message = action.error.message;
        state.taxSettings.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- taxSettingGetAllACTION end -------------------------

      //------------------------------- taxSettingChangeStatusACTION start -------------------------

      builder.addCase(taxSettingChangeStatusACTION.fulfilled, (state, action) => {

        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.taxSettings.data.records = state.taxSettings.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.taxSettingDetails.apiMsg.status = action.meta.requestStatus;
        state.taxSettingDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(taxSettingChangeStatusACTION.pending, (state, action) => {
        // console.log("taxSettingChangeStatusACTION pending", action);
        state.taxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(taxSettingChangeStatusACTION.rejected, (state, action) => {
        //console.log("taxSettingChangeStatusACTION rejected", action);
        state.taxSettingDetails.apiMsg.message = action.error.message;
        state.taxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- taxSettingChangeStatusACTION end -------------------------

      //------------------------------- taxSettingDeleteACTION start -------------------------

      builder.addCase(taxSettingDeleteACTION.fulfilled, (state, action) => {
        //console.log("taxSettingDeleteACTION", action);

        state.taxSettings.data.records = state.taxSettings.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.taxSettingDetails.apiMsg.status = action.meta.requestStatus;
        state.taxSettingDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(taxSettingDeleteACTION.pending, (state, action) => {
        //console.log("taxSettingDeleteACTION pending", action);
        state.taxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(taxSettingDeleteACTION.rejected, (state, action) => {
        //console.log("taxSettingDeleteACTION rejected", action);
        state.taxSettingDetails.apiMsg.message = action.error.message;
        state.taxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- taxSettingDeleteACTION end -------------------------
      //------------------------------- getTaxSettingACTION start -------------------------

      builder.addCase(getTaxSettingACTION.fulfilled, (state, action) => {
        //console.log("getTaxSettingACTION", action);

        state.selectedTaxSettingDetails.data = action.payload.data;
        state.selectedTaxSettingDetails.apiMsg.status = action.meta.requestStatus;
        state.selectedTaxSettingDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getTaxSettingACTION.pending, (state, action) => {
        // console.log("getTaxSettingACTION pending", action);
        state.selectedTaxSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getTaxSettingACTION.rejected, (state, action) => {
        //console.log("getTaxSettingACTION rejected", action);
        state.selectedTaxSettingDetails.apiMsg.message = action.error.message;
        state.selectedTaxSettingDetails.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- getTaxSettingACTION end -------------------------

  },
});

export const { RESET, ResetTaxSettingDetailsState } = taxSettingReducer.actions;
export default taxSettingReducer.reducer;
