import { createSlice } from "@reduxjs/toolkit";
import { getPrintLayoutAction, insertUpdatePrintLayoutAction, getPrintLayoutLookupAction } from "../../actions/printLayoutActions/printLayoutActions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    printLayout: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    printLayoutLookup: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

 
};

const printLayoutReducer = createSlice({
    name: "PrintLayout",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPrintLayoutDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
           state.printLayoutLookup = INITIAL_STATE.printLayoutLookup
        },
    },

    extraReducers: (builder) => {

        //getPrintLayoutAction start

        builder.addCase(getPrintLayoutAction.fulfilled, (state, action) => {
            state.printLayout.data = action.payload.data;
            state.printLayout.apiMsg.status = action.meta.requestStatus;
            state.printLayout.apiMsg.message = "success";
        });
        builder.addCase(getPrintLayoutAction.pending, (state, action) => {
            state.printLayout.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPrintLayoutAction.rejected, (state, action) => {
            state.printLayout.apiMsg.message = action.error.message;
            state.printLayout.apiMsg.status = action.meta.requestStatus;
        });

        // getPrintLayoutAction end

        // insertUpdatePrintLayoutAction start

        builder.addCase(
            insertUpdatePrintLayoutAction.fulfilled,
            (state, action) => {
                console.log("action",action)
                state.insertUpdateDetails.data = action.payload.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                state.printLayout.data = action.meta.arg;
                state.printLayout.data.id = action.payload.data;
                state.printLayout.apiMsg.status = action.meta.requestStatus;
                state.printLayout.apiMsg.message = "success";

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdatePrintLayoutAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePrintLayoutAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdatePrintLayoutAction end

        //------------------------------- getPrintLayoutLookupAction start -------------------------

        builder.addCase(getPrintLayoutLookupAction.fulfilled, (state, action) => {
            state.printLayoutLookup.data = action.payload.data;
            state.printLayoutLookup.apiMsg.status = action.meta.requestStatus;
            state.printLayoutLookup.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getPrintLayoutLookupAction.pending, (state, action) => {
            state.printLayoutLookup.data = null;
            state.printLayoutLookup.apiMsg.message = action.meta.requestStatus;;
            state.printLayoutLookup.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPrintLayoutLookupAction.rejected, (state, action) => {
            state.printLayoutLookup.apiMsg.message = action.error.message;
            state.printLayoutLookup.apiMsg.status = action.meta.requestStatus;
        });


        //  getPrintLayoutLookupAction end



        

    }
});
export const { RESET, ResetPrintLayoutDetailsState } = printLayoutReducer.actions;

export default printLayoutReducer.reducer;