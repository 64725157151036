import { createSlice } from "@reduxjs/toolkit";
import { getAllDashboardAction } from "../../actions/dashboardActions/dashboardActions";

const INITIAL_STATE = {
    Dashboard: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const dashboardReducer = createSlice({
    name: "Dashboard",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetDashboardState(state, action) {

        },
    },
    extraReducers: (builder) => {
        //------------------------------- getAllDayLogReportActions start -------------------------

        builder.addCase(getAllDashboardAction.fulfilled, (state, action) => {

            state.Dashboard.data = action.payload.data;
            state.Dashboard.apiMsg.status = action.meta.requestStatus;
            state.Dashboard.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllDashboardAction.pending, (state, action) => {
            state.Dashboard.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllDashboardAction.rejected, (state, action) => {
            state.Dashboard.apiMsg.message = action.error.message;
            state.Dashboard.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getAllDayLogReportActions end -------------------------

    }
});


export const { RESET, ResetDashboardState } = dashboardReducer.actions;
export default dashboardReducer.reducer;
