import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";

export const getAllDashboardAction = createAsyncThunk(
  "PosUser/GetAllPosUser",
  async (data) => {
    let d = {
      device: 1,
      restaurantID: data.id,
    };

    return apiCall("/dashboard/getdashboarddetail", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                ...response.data.data
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);