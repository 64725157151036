import { createSlice } from "@reduxjs/toolkit";
import { allergenMasterGetAllAction, allergenMasterAddUpdateACTION, allergenMasterChangeStatusACTION, allergenMasterDeleteACTION, getallergenMasterACTION } from "../../actions/allergenMasterActions";
const INITIAL_STATE = {
    allergens: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateAllergenMaster: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedAllergenMasterDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    activeInactiveAllergenDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteAllergenDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};


const allergenMasterReducer = createSlice({
    name: "allergenMaster",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetAllergenMasterDetailsState(state, action) {
            state.selectedAllergenMasterDetails = INITIAL_STATE.selectedAllergenMasterDetails;
            state.insertUpdateAllergenMaster = INITIAL_STATE.insertUpdateAllergenMaster;
        },
    },


    extraReducers: (builder) => {

        // allergenMasterGetAllAction start

        builder.addCase(allergenMasterGetAllAction.fulfilled, (state, action) => {
            state.allergens.data = action.payload.data;
            state.allergens.apiMsg.status = action.meta.requestStatus;
            state.allergens.apiMsg.message = "success";
        });
        builder.addCase(allergenMasterGetAllAction.pending, (state, action) => {
            state.allergens.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(allergenMasterGetAllAction.rejected, (state, action) => {
            state.allergens.apiMsg.message = action.error.message;
            state.allergens.apiMsg.status = action.meta.requestStatus;
        });


        // allergenMasterGetAllAction end

        // allergenMasterAddUpdateACTION start

        builder.addCase(
            allergenMasterAddUpdateACTION.fulfilled,
            (state, action) => {
                if (!action.payload.newRecord) {
                    let updatedRecord = state.allergens.data.records.map(x => {
                        if (x.id == action.meta.arg.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.allergens.data.records = updatedRecord;
                }
                else {
                    if (state.allergens.data.records.length) {
                        state.allergens.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.allergens.data.records.push(action.payload.data.data);
                    }
                }
                state.insertUpdateAllergenMaster.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateAllergenMaster.apiMsg.message = action.payload.message.message;
            }
        );
        builder.addCase(
            allergenMasterAddUpdateACTION.pending,
            (state, action) => {
                state.insertUpdateAllergenMaster.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            allergenMasterAddUpdateACTION.rejected,
            (state, action) => {
                state.insertUpdateAllergenMaster.apiMsg.message = action.error.message;
                state.insertUpdateAllergenMaster.apiMsg.status = action.meta.requestStatus;
            }
        );


        //   allergenMasterAddUpdateACTION end


        // getallergenMasterACTION  start


        builder.addCase(getallergenMasterACTION.fulfilled, (state, action) => {
            state.selectedAllergenMasterDetails.data = action.payload.data;
            state.selectedAllergenMasterDetails.apiMsg.status = action.meta.requestStatus;
            state.selectedAllergenMasterDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getallergenMasterACTION.pending, (state, action) => {
            state.selectedAllergenMasterDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallergenMasterACTION.rejected, (state, action) => {
            state.selectedAllergenMasterDetails.apiMsg.message = action.error.message;
            state.selectedAllergenMasterDetails.apiMsg.status = action.meta.requestStatus;
        });

        //  getallergenMasterACTION end



        // allergenMasterChangeStatusACTION start


        builder.addCase(allergenMasterChangeStatusACTION.fulfilled, (state, action) => {
            state.allergens.data.records = state.allergens.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveAllergenDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveAllergenDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(allergenMasterChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveAllergenDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(allergenMasterChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveAllergenDetails.apiMsg.message = action.error.message;
            state.activeInactiveAllergenDetails.apiMsg.status = action.meta.requestStatus;
        });


        // allergenMasterChangeStatusACTION end



        // allergenMasterDeleteACTION start

        builder.addCase(allergenMasterDeleteACTION.fulfilled, (state, action) => {
            console.log(action)
            state.allergens.data.records = state.allergens.data.records.filter((item, index) => item.id !== action.meta.arg.id)
            
            state.deleteAllergenDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteAllergenDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(allergenMasterDeleteACTION.pending, (state, action) => {
            state.deleteAllergenDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(allergenMasterDeleteACTION.rejected, (state, action) => {
            state.deleteAllergenDetails.apiMsg.message = action.error.message;
            state.deleteAllergenDetails.apiMsg.status = action.meta.requestStatus;
        });

        // allergenMasterDeleteACTION end


    },
});

export const { RESET, ResetAllergenMasterDetailsState } = allergenMasterReducer.actions;
export default allergenMasterReducer.reducer;