import { createSlice } from "@reduxjs/toolkit";
import { getAllDayLogReportActions, getDayLogReportAction } from "../../actions/dayLogReportActions/dayLogReportActions";

const INITIAL_STATE = {
    DayLogReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedDayLogReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const daylogReportReducer = createSlice({
    name: "DayLogReport",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetDayLogDetailsState(state, action) {
            state.selectedDayLogReport = INITIAL_STATE.selectedDayLogReport;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getAllDayLogReportActions start -------------------------

        builder.addCase(getAllDayLogReportActions.fulfilled, (state, action) => {

            state.DayLogReport.data = action.payload.data;
            state.DayLogReport.apiMsg.status = action.meta.requestStatus;
            state.DayLogReport.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllDayLogReportActions.pending, (state, action) => {
            state.DayLogReport.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllDayLogReportActions.rejected, (state, action) => {
            state.DayLogReport.apiMsg.message = action.error.message;
            state.DayLogReport.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getAllDayLogReportActions end -------------------------


        builder.addCase(getDayLogReportAction.fulfilled, (state, action) => {
            state.selectedDayLogReport.data = action.payload.data;
            state.selectedDayLogReport.apiMsg.status = action.meta.requestStatus;
            state.selectedDayLogReport.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getDayLogReportAction.pending, (state, action) => {
            state.selectedDayLogReport.data = null;
            state.selectedDayLogReport.apiMsg.message = action.meta.requestStatus;;
            state.selectedDayLogReport.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getDayLogReportAction.rejected, (state, action) => {
            state.selectedDayLogReport.apiMsg.message = action.error.message;
            state.selectedDayLogReport.apiMsg.status = action.meta.requestStatus;
        });

    }
});


export const { RESET, ResetDayLogDetailsState } = daylogReportReducer.actions;
export default daylogReportReducer.reducer;
