import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
    paymentConfigurationAddUpdateACTION,
    getPaymentConfigurationACTION
} from '../actions/paymentConfiguration.Actions'
import _ from "lodash";

const INITIAL_STATE = {
    paymentConfiguration: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatepaymentConfiguration: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }


};

const paymentConfigurationReducer = createSlice({
    name: "PaymentConfiguration",
    initialState: INITIAL_STATE,
    reducers: {
        RESETPAYMENTCONFIGURATION(state, action) {
            state.paymentConfiguration = INITIAL_STATE.paymentConfiguration
            state.insertUpdatepaymentConfiguration = INITIAL_STATE.insertUpdatepaymentConfiguration
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getPaymentConfigurationACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getPaymentConfigurationACTION.fulfilled, (state, action) => {

            state.paymentConfiguration.data = action.payload.data;
            state.paymentConfiguration.apiMsg.status = action.meta.requestStatus;
            state.paymentConfiguration.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getPaymentConfigurationACTION.pending, (state, action) => {

                state.paymentConfiguration.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getPaymentConfigurationACTION.rejected, (state, action) => {

                state.paymentConfiguration.apiMsg.message = action.error.message;
                state.paymentConfiguration.apiMsg.status = action.meta.requestStatus;
            }),
        //------------------------------- getPaymentConfigurationACTION end -------------------------
        //------------------------------- paymentConfigurationAddUpdateACTION start -------------------------
        builder.addCase(paymentConfigurationAddUpdateACTION.fulfilled, (state, action) => {

            state.insertUpdatepaymentConfiguration.apiMsg.status = action.meta.requestStatus;
            state.insertUpdatepaymentConfiguration.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(paymentConfigurationAddUpdateACTION.pending, (state, action) => {

                state.insertUpdatepaymentConfiguration.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(paymentConfigurationAddUpdateACTION.rejected, (state, action) => {

                state.insertUpdatepaymentConfiguration.apiMsg.message = action.error.message;
                state.insertUpdatepaymentConfiguration.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- paymentConfigurationAddUpdateACTION end -------------------------

    }
});

export const { RESETPAYMENTCONFIGURATION } = paymentConfigurationReducer.actions;
export default paymentConfigurationReducer.reducer;
