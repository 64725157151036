import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  kitchenCommentCategoryAddUpdateACTION,
  kitchenCommentCategoryGetAllACTION,
  kitchenCommentCategoryChangeStatusACTION,
  kitchenCommentCategoryDeleteACTION,
  getKitchenCommentCategoryACTION 
} from "../../actions/kitchenCommentCategoryActions";

const INITIAL_STATE = {
  kitchenCommentCategories: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
 kitchenCommentCategoryDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateKitchenCommentCategoryDetails:{
    apiMsg:{
      message: null,
      status: null,
      type: null,
    },
  }
};

const kitchenCommentCategoryReducer = createSlice({
  name: "KitchenCommentCategory",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetKitchenCommentCategoryDetailsState(state, action) {
      state.kitchenCommentCategoryDetails = INITIAL_STATE.kitchenCommentCategoryDetails;
      state.insertUpdateKitchenCommentCategoryDetails = INITIAL_STATE.insertUpdateKitchenCommentCategoryDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- kitchenCommentCategoryAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(kitchenCommentCategoryAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("kitchenCommentCategoryAddUpdateACTION", action);

        //state.kitchenCommentCategories.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.kitchenCommentCategories.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.meta.arg;
            }
            else{
              return x;
            }
          })

          state.kitchenCommentCategories.data.records=updatedRecord;
        }
        else{
          action.meta.arg.id=action.payload.data.data;
          if(state.kitchenCommentCategories.data.records.length){
            state.kitchenCommentCategories.data.records.unshift(action.meta.arg);
          }
          else{
            state.kitchenCommentCategories.data.records.push(action.meta.arg);
          }
        }

        state.insertUpdateKitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateKitchenCommentCategoryDetails.apiMsg.message = action.payload.message.message;
    }),
    builder.addCase(kitchenCommentCategoryAddUpdateACTION.pending, (state, action) => {
      state.insertUpdateKitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
    }),
    builder.addCase(kitchenCommentCategoryAddUpdateACTION.rejected, (state, action) => {
      state.insertUpdateKitchenCommentCategoryDetails.apiMsg.message = action.error.message;
      state.insertUpdateKitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
    }),

      //------------------------------- kitchenCommentCategoryAddUpdateACTION start -------------------------

      //------------------------------- kitchenCommentCategoryGetAllACTION start -------------------------

      builder.addCase(kitchenCommentCategoryGetAllACTION.fulfilled, (state, action) => {
        console.log("kitchenCommentCategoryGetAllACTION", action);

        state.kitchenCommentCategories.data = action.payload.data;
        state.kitchenCommentCategories.apiMsg.status = action.meta.requestStatus;
        state.kitchenCommentCategories.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenCommentCategoryGetAllACTION.pending, (state, action) => {
        //console.log("kitchenCommentCategoryGetAllACTION pending", action);
        state.kitchenCommentCategories.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenCommentCategoryGetAllACTION.rejected, (state, action) => {
        //console.log("kitchenCommentCategoryGetAllACTION rejected", action);
        state.kitchenCommentCategories.apiMsg.message = action.error.message;
        state.kitchenCommentCategories.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- kitchenCommentCategoryGetAllACTION end -------------------------

    //------------------------------- kitchenCommentCategoryChangeStatusACTION start -------------------------

    builder.addCase(kitchenCommentCategoryChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("kitchenCommentCategoryChangeStatusACTION", action);

        state.kitchenCommentCategories.data.records = state.kitchenCommentCategories.data.records.map((item)=>{

          return {
              ...item,
              active:action.payload.id === item.id ? !item.active : item.active 
          }
      })
        state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
        state.kitchenCommentCategoryDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenCommentCategoryChangeStatusACTION.pending, (state, action) => {
        //console.log("kitchenCommentCategoryChangeStatusACTION pending", action);
        state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenCommentCategoryChangeStatusACTION.rejected, (state, action) => {
        //console.log("kitchenCommentCategoryChangeStatusACTION rejected", action);
        state.kitchenCommentCategoryDetails.apiMsg.message = action.error.message;
        state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- kitchenCommentCategoryChangeStatusACTION end -------------------------

     //------------------------------- kitchenCommentCategoryDeleteACTION start -------------------------
    
  builder.addCase(kitchenCommentCategoryDeleteACTION.fulfilled, (state, action) => {
  //console.log("kitchenCommentCategoryDeleteACTION", action);

  state.kitchenCommentCategories.data.records = state.kitchenCommentCategories.data.records.filter((item,index)=>item.id !== action.payload.data.id)
  
  state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
  state.kitchenCommentCategoryDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(kitchenCommentCategoryDeleteACTION.pending, (state, action) => {
    //console.log("kitchenCommentCategoryDeleteACTION pending", action);
    state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(kitchenCommentCategoryDeleteACTION.rejected, (state, action) => {
    //console.log("kitchenCommentCategoryDeleteACTION rejected", action);
    state.kitchenCommentCategoryDetails.apiMsg.message = action.error.message;
    state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
  }),
  //------------------------------- kitchenCommentCategoryDeleteACTION end -------------------------

  //------------------------------- getKitchenCommentCategoryACTION start -------------------------

  builder.addCase(getKitchenCommentCategoryACTION.fulfilled, (state, action) => {
    //console.log("getKitchenCommentCategoryACTION", action);
    state.kitchenCommentCategoryDetails.data = action.payload.data;
    state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
    state.kitchenCommentCategoryDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getKitchenCommentCategoryACTION.pending, (state, action) => {
    //console.log("getKitchenCommentCategoryACTION pending", action);
    state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getKitchenCommentCategoryACTION.rejected, (state, action) => {
    //console.log("getKitchenCommentCategoryACTION rejected", action);
    state.kitchenCommentCategoryDetails.apiMsg.message = action.error.message;
    state.kitchenCommentCategoryDetails.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getKitchenCommentCategoryACTION end -------------------------
    
  },
});

export const { RESET,ResetKitchenCommentCategoryDetailsState } = kitchenCommentCategoryReducer.actions;
export default kitchenCommentCategoryReducer.reducer;
