import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  productMasterGetAllACTION,
  changeStatusACTION,
  addUpdateACTION,
  addUpdatePricingACTION,
  getProductMasterACTION,
  getAddonVariantPriceACTION,
  addonVariantPriceUpdateACTION,
  getProductPriceMasterACTION,
  deleteACTION,
  assignMenuTypeUpdateACTION,
  getKitchenPrintersACTION,
  kitchenPrintersUpdateACTION,
  getAllComboTtemsACTION,
  getComboTtemsACTION,
  comboItemUpdateACTION,
  getproductpriceAction,
  productpriceupdateAction,
  getAllPromotionProductAction
} from "../../actions/menuItemActions";
import { Enums } from "../../../helper/constants/emuns-const";


let INITIAL_STATE = {
  stepper: {
    id: 1
  },
  menuItem: {
    data: { records: [] },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  menuItemDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  menuItemPriceDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productVariantPriceMasters: {
    data: [],
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productVariantPriceMastersInsertUpdate: {
    data: [],
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  addonVariantPrice: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateaddonVariantPrice: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateMenuItemDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  assignMenuType: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  kitchenPrinters: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateKitchenPrinters: {
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  comboItem: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  comboItemDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  inertUpdateComboItem: {
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productPricesData: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productPricesDataInsertUpdate: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  promotionProducts: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
};

const menuitemReducer = createSlice({
  name: "MenuItem",
  initialState: INITIAL_STATE,
  reducers: {
    NEXTPREIOUS(state, action) {
      state.stepper.id = action.payload
    },
    VARIANTADD(state, action) {
      // console.log('VARIANTADD ',action.payload)
      state.productVariantPriceMasters.apiMsg.status = "fulfilled";
      state.productVariantPriceMasters.data.push(action.payload)
    },
    VARIANTUPDATE(state, action) {
      console.log('VARIANTUPDATE ', action.payload)
      state.productVariantPriceMasters.apiMsg.status = "fulfilled";
      state.productVariantPriceMasters.data = [...action.payload]
    },
    VARIANTREMOVE(state, action) {

      state.productVariantPriceMasters.data
        = state.productVariantPriceMasters.data.filter((item, index) => item.indexId !== action.payload)
      state.productVariantPriceMasters.apiMsg.status = "fulfilled";
    },
    ADDONPRICESUPDATE(state, action) {
      if (action.payload.included !== undefined) {
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].included = action.payload.included;
      } else if (action.payload.price !== undefined) {
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].price = action.payload.price ? parseFloat(action.payload.price) : null;
      } else if (action.payload.enabled !== undefined) {
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].enabled = action.payload.enabled;
      }
      else if (typeof (action.payload.expanded) != "undefined") {
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].expanded = action.payload.expanded;
      }
      else if (typeof (action.payload.activeInactiveVariants) != "undefined") {

        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].activeInactiveVariants = action.payload.activeInactiveVariants;
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].addonVariantPrices.map((addonVariantPricesItem, i) => {
          //console.log("enabled",action.payload.activeInactiveVariants);
          state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.indexID].addonVariantPrices[i].enabled = action.payload.activeInactiveVariants;
        })
      }
    },
    ADDONVARIANTPRICESUPDATE(state, action) {
      if (action.payload.enabled !== undefined) {
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.addOnIndexID].addonVariantPrices[action.payload.indexID].enabled = action.payload.enabled;
      } else if (action.payload.price !== undefined) {
        //console.log("ADDONVARIANTPRICESUPDATE", state.addonVariantPrice.data.productAddOnPrices);
        //console.log("ADDONVARIANTPRICESUPDATE action.payload", action.payload);
        state.addonVariantPrice.data.productAddOnPrices[action.payload.optionSetIndexID].addOnPrices[action.payload.addOnIndexID].addonVariantPrices[action.payload.indexID].price = action.payload.price ? parseFloat(action.payload.price) : null;
      }
    },
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetMenuItemDetailsState(state, action) {
      state.menuItemDetails = INITIAL_STATE.menuItemDetails;
      state.menuItemPriceDetails = INITIAL_STATE.menuItemPriceDetails;
      state.productVariantPriceMasters = INITIAL_STATE.insertUpdateMenuItemDetails;
      state.productVariantPriceMastersInsertUpdate = INITIAL_STATE.productVariantPriceMastersInsertUpdate;
      // state.stepper = INITIAL_STATE.stepper;
    },
    RESETPRODUCTVARIANTPRICEMASTER(state, action) {
      state.productVariantPriceMasters = INITIAL_STATE.productVariantPriceMasters;
      state.addonVariantPrice = INITIAL_STATE.addonVariantPrice;

    },
    ResetAllState(state, action) {
      state.menuItemDetails = INITIAL_STATE.menuItemDetails;
      state.menuItemPriceDetails = INITIAL_STATE.menuItemPriceDetails;
      state.addonVariantPrice = INITIAL_STATE.addonVariantPrice;
      state.productVariantPriceMasters = INITIAL_STATE.productVariantPriceMasters;
      state.productVariantPriceMastersInsertUpdate = INITIAL_STATE.productVariantPriceMastersInsertUpdate;
      state.kitchenPrinters = INITIAL_STATE.kitchenPrinters;
      state.comboItem = INITIAL_STATE.comboItem;
      state.comboItemDetails = INITIAL_STATE.comboItemDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- addUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(addUpdateACTION.fulfilled, (state, action) => {
      let temp = {}
      //state.categories.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.menuItem.data.records.map(x => {
          if (x.id == action.meta.arg.id) {

            temp = { ...action.meta.arg, ...action.payload.data.data }
            return temp;
          }
          else {
            return x;
          }
        })
        state.menuItem.data.records = updatedRecord;
      }
      else {


        if (state.menuItem.data.records.length) {
          state.menuItem.data.records.unshift(action.payload.data.data);
        }
        else {
          state.menuItem.data.records.push(action.payload.data.data);
        }
      }

      // action.meta.arg = action.payload.data.data.id;
      temp = { ...action.meta.arg, ...action.payload.data.data }

      // action.meta.arg.categoryName = action.payload.data.data.categoryName;
      state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
      state.menuItemDetails.apiMsg.message = action.payload.message.message;
      state.menuItemDetails.data = temp;

      state.insertUpdateMenuItemDetails.data = action.payload.data.data;
      state.insertUpdateMenuItemDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateMenuItemDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(addUpdateACTION.pending, (state, action) => {
        //console.log("addUpdateACTION pending", action);
        state.insertUpdateMenuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addUpdateACTION.rejected, (state, action) => {
        //console.log("addUpdateACTION rejected", action);
        state.insertUpdateMenuItemDetails.apiMsg.message = action.error.message;
        state.insertUpdateMenuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addUpdateACTION end -------------------------
      //------------------------------- addUpdatePricingACTION start -------------------------

      builder.addCase(addUpdatePricingACTION.fulfilled, (state, action) => {

        state.productVariantPriceMastersInsertUpdate.apiMsg.status = action.meta.requestStatus;

        state.productVariantPriceMastersInsertUpdate.apiMsg.message = action.payload.message.message;

        state.menuItemDetails.data.productVariantPriceMasters = action.meta.arg.productVariantPriceMasters;
      }),
      builder.addCase(addUpdatePricingACTION.pending, (state, action) => {
        state.productVariantPriceMastersInsertUpdate.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addUpdatePricingACTION.rejected, (state, action) => {
        state.productVariantPriceMastersInsertUpdate.apiMsg.message = action.error.message;
        state.productVariantPriceMastersInsertUpdate.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addUpdatePricingACTION end -------------------------

      //------------------------------- deleteACTION start -------------------------

      builder.addCase(deleteACTION.fulfilled, (state, action) => {

        state.menuItem.data.records = state.menuItem.data.records.filter((item, index) => item.id !== action.payload.data.id)
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
        state.menuItemDetails.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(deleteACTION.pending, (state, action) => {
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteACTION.rejected, (state, action) => {
        state.menuItemDetails.apiMsg.message = action.error.message;
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- deleteACTION end -------------------------

      //------------------------------- productMasterGetAllACTION start -------------------------

      builder.addCase(productMasterGetAllACTION.fulfilled, (state, action) => {

        state.menuItem.data = action.payload.data;
        state.menuItem.apiMsg.status = action.meta.requestStatus;
        state.menuItem.apiMsg.message = action.payload.message.message;

      }),
      builder.addCase(productMasterGetAllACTION.pending, (state, action) => {

        state.menuItem.apiMsg.status = action.meta.requestStatus;

      }),
      builder.addCase(productMasterGetAllACTION.rejected, (state, action) => {

        state.menuItem.apiMsg.message = action.error.message;
        state.menuItem.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- productMasterGetAllACTION end -------------------------
      //------------------------------- changeStatusACTION start -------------------------

      builder.addCase(changeStatusACTION.fulfilled, (state, action) => {

        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.menuItem.data.records = state.menuItem.data.records.map((item) => {
          if (action.meta.arg.statusType == 4 || action.meta.arg.statusType == 5) {
            return {
              ...item,
              outOfStock: action.payload.id === item.id ? !item.outOfStock : item.outOfStock,
            }

          }
          else if(action.meta.arg.statusType == 13 || action.meta.arg.statusType == 14){
            return {
              ...item,
              posOutOfStock: action.payload.id === item.id ? !item.posOutOfStock : item.posOutOfStock,
            }
          }
          else {
            return {
              ...item,
              active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
              sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
            }

          }
        })
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
        state.menuItemDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changeStatusACTION.pending, (state, action) => {

        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;;
      }),
      builder.addCase(changeStatusACTION.rejected, (state, action) => {

        state.menuItemDetails.apiMsg.message = action.error.message;
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- changeStatusACTION end -------------------------
      //------------------------------- getProductMasterACTION start -------------------------

      builder.addCase(getProductMasterACTION.fulfilled, (state, action) => {

        state.menuItemDetails.data = action.payload.data;
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
        state.menuItemDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getProductMasterACTION.pending, (state, action) => {

        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getProductMasterACTION.rejected, (state, action) => {

        state.menuItemDetails.apiMsg.message = action.error.message;
        state.menuItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getProductMasterACTION end -------------------------

      //------------------------------- getProductPriceMasterACTION start -------------------------

      builder.addCase(getProductPriceMasterACTION.fulfilled, (state, action) => {
        //console.log("getProductPriceMasterACTION", action);
        state.menuItemPriceDetails.data = action.payload.data;
        state.menuItemPriceDetails.apiMsg.status = action.meta.requestStatus;
        state.menuItemPriceDetails.apiMsg.message = action.payload.message.message;
        // state.productVariantPriceMasters.data = action.payload.data;
        // state.productVariantPriceMasters.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getProductPriceMasterACTION.pending, (state, action) => {

        state.menuItemPriceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getProductPriceMasterACTION.rejected, (state, action) => {

        state.menuItemPriceDetails.apiMsg.message = action.error.message;
        state.menuItemPriceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getProductPriceMasterACTION end -------------------------

      //------------------------------- getAddonVariantPriceACTION start -------------------------

      builder.addCase(getAddonVariantPriceACTION.fulfilled, (state, action) => {

        if (action.payload.data && action.payload.data.productAddOnPrices && action.payload.data.productAddOnPrices.length) {
          action.payload.data.productAddOnPrices.map((productAddOnPricesItem, i) => {

            if (productAddOnPricesItem.addOnPrices && productAddOnPricesItem.addOnPrices.length) {
              productAddOnPricesItem.addOnPrices.map((addOnPricesItem, j) => {
                //console.log(addOnPricesItem)
                action.payload.data.productAddOnPrices[i].addOnPrices[j].expanded = addOnPricesItem.addonVariantPrices.filter((addonVariantPricesRow) => addonVariantPricesRow.enabled).length ? true : false;
                action.payload.data.productAddOnPrices[i].addOnPrices[j].activeInactiveVariants = action.payload.data.productAddOnPrices[i].addOnPrices[j].expanded;
              });
            }
          });

        }
        state.addonVariantPrice.data = action.payload.data;
        state.addonVariantPrice.apiMsg.status = action.meta.requestStatus;
        state.addonVariantPrice.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getAddonVariantPriceACTION.pending, (state, action) => {

        state.addonVariantPrice.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getAddonVariantPriceACTION.rejected, (state, action) => {

        state.addonVariantPrice.apiMsg.message = action.error.message;
        state.addonVariantPrice.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getAddonVariantPriceACTION end -------------------------
      //------------------------------- addonVariantPriceUpdateACTION start -------------------------

      builder.addCase(addonVariantPriceUpdateACTION.fulfilled, (state, action) => {

        // state.addonVariantPrice.data = action.payload.data;
        state.insertUpdateaddonVariantPrice.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateaddonVariantPrice.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(addonVariantPriceUpdateACTION.pending, (state, action) => {

        state.insertUpdateaddonVariantPrice.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addonVariantPriceUpdateACTION.rejected, (state, action) => {

        state.insertUpdateaddonVariantPrice.apiMsg.message = action.error.message;
        state.insertUpdateaddonVariantPrice.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addonVariantPriceUpdateACTION end -------------------------
      //------------------------------- assignMenuTypeUpdateACTION start -------------------------

      builder.addCase(assignMenuTypeUpdateACTION.fulfilled, (state, action) => {

        state.assignMenuType.data = action.payload.data;
        state.assignMenuType.apiMsg.status = action.meta.requestStatus;
        state.assignMenuType.apiMsg.message = action.payload.message.message;
        let updatedRecord = state.menuItem.data.records.map(x => {
          if (x.id == action.meta.arg.productID) {
            x.menuTypeIncluded = action.meta.arg.assign;
            return x;
          }
          else {
            return x;
          }
        })

        state.menuItem.data.records = updatedRecord;
      }),
      builder.addCase(assignMenuTypeUpdateACTION.pending, (state, action) => {

        state.assignMenuType.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(assignMenuTypeUpdateACTION.rejected, (state, action) => {

        state.assignMenuType.apiMsg.message = action.error.message;
        state.assignMenuType.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- assignMenuTypeUpdateACTION end -------------------------
      //------------------------------- getKitchenPrintersACTION start -------------------------

      builder.addCase(getKitchenPrintersACTION.fulfilled, (state, action) => {

        state.kitchenPrinters.data = action.payload.data;
        state.kitchenPrinters.apiMsg.status = action.meta.requestStatus;
        state.kitchenPrinters.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getKitchenPrintersACTION.pending, (state, action) => {

        state.kitchenPrinters.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getKitchenPrintersACTION.rejected, (state, action) => {

        state.kitchenPrinters.apiMsg.message = action.error.message;
        state.kitchenPrinters.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getKitchenPrintersACTION end -------------------------
      //------------------------------- kitchenPrintersUpdateACTION start -------------------------

      builder.addCase(kitchenPrintersUpdateACTION.fulfilled, (state, action) => {


        state.insertUpdateKitchenPrinters.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateKitchenPrinters.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenPrintersUpdateACTION.pending, (state, action) => {

        state.insertUpdateKitchenPrinters.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenPrintersUpdateACTION.rejected, (state, action) => {

        state.insertUpdateKitchenPrinters.apiMsg.message = action.error.message;
        state.insertUpdateKitchenPrinters.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- kitchenPrintersUpdateACTION end -------------------------
      //------------------------------- getAllComboTtemsACTION start -------------------------

      builder.addCase(getAllComboTtemsACTION.fulfilled, (state, action) => {
        state.comboItem.data = action.payload.data;
        state.comboItem.apiMsg.status = action.meta.requestStatus;
        state.comboItem.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getAllComboTtemsACTION.pending, (state, action) => {

        state.comboItem.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getAllComboTtemsACTION.rejected, (state, action) => {

        state.comboItem.apiMsg.message = action.error.message;
        state.comboItem.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getAllComboTtemsACTION end -------------------------
      //------------------------------- getComboTtemsACTION start -------------------------

      builder.addCase(getComboTtemsACTION.fulfilled, (state, action) => {
        state.comboItemDetails.data = action.payload.data;
        state.comboItemDetails.apiMsg.status = action.meta.requestStatus;
        state.comboItemDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getComboTtemsACTION.pending, (state, action) => {

        state.comboItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getComboTtemsACTION.rejected, (state, action) => {

        state.comboItemDetails.apiMsg.message = action.error.message;
        state.comboItemDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getComboTtemsACTION end -------------------------
      //------------------------------- comboItemUpdateACTION start -------------------------

      builder.addCase(comboItemUpdateACTION.fulfilled, (state, action) => {
        // console.log('comboItemUpdateACTION ', action)
        state.comboItemDetails.data = action.payload.data.data;
        state.inertUpdateComboItem.apiMsg.status = action.meta.requestStatus;
        state.inertUpdateComboItem.apiMsg.message = action.payload.data.message.message;
      }),
      builder.addCase(comboItemUpdateACTION.pending, (state, action) => {

        state.inertUpdateComboItem.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(comboItemUpdateACTION.rejected, (state, action) => {

        state.inertUpdateComboItem.apiMsg.message = action.error.message;
        state.inertUpdateComboItem.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- comboItemUpdateACTION end -------------------------
      //------------------------------- getproductpriceAction start -------------------------

      builder.addCase(getproductpriceAction.fulfilled, (state, action) => {
        //console.log("action", action);
        state.productPricesData.data = action.payload.data.data;
        state.productPricesData.apiMsg.status = action.meta.requestStatus;
        state.productPricesData.apiMsg.message = action.payload.data.message.message;
      }),
      builder.addCase(getproductpriceAction.pending, (state, action) => {

        state.productPricesData.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getproductpriceAction.rejected, (state, action) => {

        state.productPricesData.apiMsg.message = action.error.message;
        state.productPricesData.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getproductpriceAction end -------------------------
      //------------------------------- productpriceupdateAction start -------------------------

      builder.addCase(productpriceupdateAction.fulfilled, (state, action) => {
        //console.log("action", action);
        state.productPricesData.data = action.meta.arg;
        state.productPricesDataInsertUpdate.data = action.payload.data.data;
        state.productPricesDataInsertUpdate.apiMsg.status = action.meta.requestStatus;
        state.productPricesDataInsertUpdate.apiMsg.message = action.payload.data.message.message;
      }),
      builder.addCase(productpriceupdateAction.pending, (state, action) => {

        state.productPricesDataInsertUpdate.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(productpriceupdateAction.rejected, (state, action) => {

        state.productPricesDataInsertUpdate.apiMsg.message = action.error.message;
        state.productPricesDataInsertUpdate.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- productpriceupdateAction end -------------------------

      //------------------------------- getAllPromotionProductAction start -------------------------

      builder.addCase(getAllPromotionProductAction.fulfilled, (state, action) => {
        state.promotionProducts.data = action.payload.data;
        state.promotionProducts.apiMsg.status = action.meta.requestStatus;
        state.promotionProducts.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getAllPromotionProductAction.pending, (state, action) => {

        state.promotionProducts.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getAllPromotionProductAction.rejected, (state, action) => {

        state.promotionProducts.apiMsg.message = action.error.message;
        state.promotionProducts.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- getAllPromotionProductAction end -------------------------
  },
});

export const { NEXTPREIOUS, VARIANTADD, VARIANTUPDATE, VARIANTREMOVE, ADDONPRICESUPDATE, ADDONVARIANTPRICESUPDATE, RESET, ResetMenuItemDetailsState, RESETPRODUCTVARIANTPRICEMASTER, ResetAllState } = menuitemReducer.actions;
export default menuitemReducer.reducer;
