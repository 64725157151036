import { createSlice } from "@reduxjs/toolkit";
import { insertUpdateGeneralSettingAction, getGeneralSettingAction } from "../../actions/settings/generalSettingAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    insertUpdateDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedGeneralSetting: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const generalSettingReducer = createSlice({
    name: "General Setting",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetGeneralSettingDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedGeneralSetting = INITIAL_STATE.selectedGeneralSetting;
        },
    },

    extraReducers: (builder) => {

        // insertUpdateGeneralSettingAction start

        builder.addCase(
            insertUpdateGeneralSettingAction.fulfilled,
            (state, action) => {
                console.log("action");
                state.insertUpdateDetails.data = action.payload.data.data;
                console.log(" action.payload.data.data", action.payload.data.data)
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateGeneralSettingAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateGeneralSettingAction.rejected,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateGeneralSettingAction end

        //------------------------------- getGeneralSettingAction start -------------------------

        builder.addCase(getGeneralSettingAction.fulfilled, (state, action) => {
            state.selectedGeneralSetting.data = action.payload.data;
            state.selectedGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.selectedGeneralSetting.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getGeneralSettingAction.pending, (state, action) => {
            state.selectedGeneralSetting.data = null;
            state.selectedGeneralSetting.apiMsg.message = action.meta.requestStatus;;
            state.selectedGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getGeneralSettingAction.rejected, (state, action) => {
            state.selectedGeneralSetting.apiMsg.message = action.error.message;
            state.selectedGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });


        //  getGeneralSettingAction end






    }




});

export const { RESET, ResetGeneralSettingDetailsState } = generalSettingReducer.actions;

export default generalSettingReducer.reducer;