const Enums = {
  MenuTypes: {
    Base: 0,
    Pickup: 1,
    Delivery: 2,
    DineIn: 3,
    Bar: 4,
    Website: 5,
  },

  ServiceTypes: {
    Pickup: 1,
    Delivery: 2,
    CarPickup: 3,
    DineIn: 4,
    Bar: 5,
  },

  ScheduleDayTypes: {
    Restaurant: 1,
    RestaurantService: 2,
    Category: 3,
    Product: 4,
    MenuType: 5
  },
  LookupType: {
    Country: 1,
    SystemLocale: 2,
    Timezone: 3,
    Currency: 4,
    DateFormat: 5,
    SubTypeMapping: 6
  },
  PromotionTypes: {
    Promocode: 1,
    XXNowXX: 2
  },
  Status: {
    Active: 1,
    InActive: 2,
    Expired: 3
  },
  ChangeStatus: {
    Activate: 1,
    DeActivate: 2,
    Delete: 3,
    OutOfStock: 4,
    InStock: 5,
    Block: 6,
    UnBlock: 7,
    SetDefault: 8,
    SortOrder: 9,
    DelinkDevice: 10,
    LinkDevice: 11,
    RemoveDefault: 12,
    POSOutOfStock: 13,
    POSInStock: 14,
  },
  ReportType: {
    Year: 1,
    Month: 2,
    Week: 3,
    DateRange: 4,
    Today: 5
  },
  menuLinks: {
    Custom: 0,
    OrderOnline: 1,
    Location: 2,
    AboutUs: 3,
    Contact: 4
  },
  applicationType: {
    None: 0,
    Web: 1,
    OrderApp: 2
  },
  RestaurantSettingType: {
    SOFTWORDPAY_SUBCRIPTION: 0,
    PAYMENT_SURCHARGE: 2,
    STRIPE: 1,
    RECEIPT_SETTINGS: 3,
    GENERAL_SETTINGS: 4,
    POS_GENERAL_SETTINGS: 5,
    DOJO: 6,
    DAY_END_REPORT_SETTINGS: 7,
    SOCIAL_SETTINGS: 8
  },
  FloorMapDataType: {
    table: 1,
    custom_area: 2
  },
  POSPaymentType: {
    Cash: 1,
    Card: 2,
    Others: 4
  },
  CashReportType: {
    CashIn: 1,
    CashOut: 2
  },
  PayInOutReportType: {
    PayIn: 1,
    PayOut: 2,
  },
  PrintLayoutLookupType: {
    Format: 0,
    KitchenPrint: 1,
    CustomerBill: 2,
    Receipt: 3
  },
  PrintLayoutType: {
    KitchenPrint: 1,
    CustomerBill: 2,
    Receipt: 3
  },
  OrderType: {
    Pickup: 1,
    Delivery: 2,
    ParkingPickup: 3,
    DineIn: 4,
    Bar: 5,
    WebOrder: 6,
    DineInRunningOrder: 7,
    DineInOrder: 8
  }
};

const EnumskeyValue = {
  MenuTypes: [
    { key: "Base", value: 0 },
    { key: "Pickup", value: 1 },
    { key: "Delivery", value: 2 },
    { key: "DineIn", value: 3 },
    { key: "Bar", value: 4 },
    { key: "Website", value: 5 },
  ],
  MapTypes: [
    { key: "POS Setting", value: 1 },
    { key: "Web Setting", value: 2 },
  ],
  SubServiceTypeSMapping: [
    { key: "Pickup", value: 1 },
    { key: "Delivery", value: 2 },
    { key: "DineIn", value: 3 },
    { key: "Bar", value: 4 },
    { key: "Restaurant", value: 5 },
    { key: "Website", value: 6 },
    { key: "Uber", value: 7 },
    { key: "Justeat", value: 8 },
  ],
  productType: [
    { key: "Standard", value: 1 },
    { key: "Combo", value: 2 },
    { key: "Customize", value: 3 },
    { key: "Manual", value: 4 }
  ],
  serviceType: [
    { key: "Pickup", value: 1 },
    { key: "Delivery", value: 2 },
    { key: "ParkingPickup", value: 3 },
  ],
  orderStatusOne: [
    { key: "Placed", value: 3, text: 'Place' },
    { key: "Confirmed", value: 4, text: 'Confirm' },
    { key: "Ready", value: 5, text: 'Ready' },
    { key: "Completed", value: 6, text: 'Complete' },
    { key: "Pending", value: 1, text: 'Pending' },
  ],
  orderStatusTwo: [
    { key: "Cancelled", value: 7, text: 'Cancel' },
    { key: "Refunded", value: 8, text: 'Refund' },
    { key: "OnTheWay", value: 9, text: 'On the way' },
    { key: "Un-Paid", value: 2, text: 'Un-paid' },
  ],
  OrderStatusList: [
    { key: "Place", value: 3, text: 'Place' },
    { key: "Confirm", value: 4, text: 'Confirm' },
    { key: "Ready", value: 5, text: 'Ready' },
    { key: "Complete", value: 6, text: 'Complete' },
    { key: "Cancel", value: 7, text: 'Cancel' },
    { key: "Refund", value: 8, text: 'Refund' },
    { key: "OnTheWay", value: 9, text: 'On the way' },
    { key: "UnPaid", value: 2, text: 'Un-paid' },
    { key: "Pending", value: 1, text: 'Pending' },
  ],
  paymentType: [
    { key: "Cash", value: 1 },
    { key: "Card", value: 2 },
  ],
  serviceChargeType: [
    { key: "Amount", value: 0 },
    { key: "Percentage ", value: 1 },
  ],
  promotionTypes: [
    { key: "Promocode", value: Enums.PromotionTypes.Promocode, text: 'Promo Code' },
    { key: "XXNowXX ", value: Enums.PromotionTypes.XXNowXX, text: 'Was X.XX Now X.XX' },
  ],
  discountTypes: [
    { key: "Amount", value: 0, text: 'Amount' },
    { key: "Percentage", value: 1, text: 'Percentage' },
  ],
  menuLinks: [
    { key: Enums.menuLinks.Location, value: "22222222-2222-2222-2222-222222222222", text: 'Restaurants', desc: "Restaurants Section", linkType: Enums.menuLinks.Location, icon: "fa-solid fa-utensils" },
    { key: Enums.menuLinks.AboutUs, value: "33333333-3333-3333-3333-333333333333", text: 'About Us', desc: "About Us Section", linkType: Enums.menuLinks.AboutUs, icon: "fa-solid fa-users" },
    { key: Enums.menuLinks.Contact, value: "44444444-4444-4444-4444-444444444444", text: 'Contact Us', desc: "Contact Us Section", linkType: Enums.menuLinks.Contact, icon: "fa-solid fa-envelope" },
    { key: Enums.menuLinks.Custom, value: "", text: 'Custom', desc: "", linkType: 0, icon: "fa-solid fa-square-up-right" },
    { key: Enums.menuLinks.OrderOnline, value: "11111111-1111-1111-1111-111111111111", text: 'Order Online', desc: "Store Page / Restaurants Section", linkType: Enums.menuLinks.OrderOnline, icon: "fa-solid fa-cart-shopping" },
  ],

  RestaurantDeviceType: [

    { key: "TILL", value: 1, text: 'TILL' },
    { key: "WAITER_APP", value: 2, text: 'WAITER_APP' }

  ],

  RestaurantDeviceDBType: [
    { key: "POSTGRESS", value: 1, text: 'POSTGRESS' },
    { key: "SQLLITE", value: 2, text: 'SQLLITE' },

  ],
  POSPaymentType: [
    { key: "Cash", value: Enums.POSPaymentType.Cash },
    { key: "Card", value: Enums.POSPaymentType.Card },
    { key: "Other", value: Enums.POSPaymentType.Others },
  ],
};
const defaultButtonColor = '#337ab7'
const defaultTextColor = '#ffffff'
const defaultBackColor = '#337ab7'
export { Enums, EnumskeyValue, defaultButtonColor, defaultTextColor, defaultBackColor };
