import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  reportDepartmentAddUpdateACTION,
  reportDepartmentGetAllACTION,
  reportDepartmentChangeStatusACTION,
  reportDepartmentDeleteACTION,
  getReportDepartmentACTION 
} from "../../actions/ProductItems/reportDepartmentActions";

const INITIAL_STATE = {
  reportDepartments: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
 reportDepartmentDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedReportDepartmentDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateReportDepartmentDetails:{
    apiMsg:{
      message: null,
      status: null,
      type: null,
    },
  }
};

const reportDepartmentReducer = createSlice({
  name: "ReportDepartment",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetReportDepartmentDetailsState(state, action) {
      state.reportDepartmentDetails = INITIAL_STATE.reportDepartmentDetails;
      state.insertUpdateReportDepartmentDetails = INITIAL_STATE.insertUpdateReportDepartmentDetails;
      state.selectedReportDepartmentDetails=INITIAL_STATE.selectedReportDepartmentDetails
    },
  },
  extraReducers: (builder) => {
    //------------------------------- reportDepartmentAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(reportDepartmentAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("reportDepartmentAddUpdateACTION", action);

        //state.reportDepartments.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.reportDepartments.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.meta.arg;
            }
            else{
              return x;
            }
          })

          state.reportDepartments.data.records=updatedRecord;
        }
        else{
          action.meta.arg.id=action.payload.data.data;
          if(state.reportDepartments.data.records.length){
            state.reportDepartments.data.records.unshift(action.meta.arg);
          }
          else{
            state.reportDepartments.data.records.push(action.meta.arg);
          }
        }

        state.insertUpdateReportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateReportDepartmentDetails.apiMsg.message = action.payload.message.message;
    }),
    builder.addCase(reportDepartmentAddUpdateACTION.pending, (state, action) => {
      state.insertUpdateReportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
    }),
    builder.addCase(reportDepartmentAddUpdateACTION.rejected, (state, action) => {
      state.insertUpdateReportDepartmentDetails.apiMsg.message = action.error.message;
      state.insertUpdateReportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
    }),

      //------------------------------- reportDepartmentAddUpdateACTION start -------------------------

      //------------------------------- reportDepartmentGetAllACTION start -------------------------

      builder.addCase(reportDepartmentGetAllACTION.fulfilled, (state, action) => {
       // console.log("reportDepartmentGetAllACTION", action);

        state.reportDepartments.data = action.payload.data;
        state.reportDepartments.apiMsg.status = action.meta.requestStatus;
        state.reportDepartments.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(reportDepartmentGetAllACTION.pending, (state, action) => {
        //console.log("reportDepartmentGetAllACTION pending", action);
        state.reportDepartments.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(reportDepartmentGetAllACTION.rejected, (state, action) => {
        //console.log("reportDepartmentGetAllACTION rejected", action);
        state.reportDepartments.apiMsg.message = action.error.message;
        state.reportDepartments.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- reportDepartmentGetAllACTION end -------------------------

    //------------------------------- reportDepartmentChangeStatusACTION start -------------------------

    builder.addCase(reportDepartmentChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("reportDepartmentChangeStatusACTION", action);

        state.reportDepartments.data.records = state.reportDepartments.data.records.map((item)=>{

          return {
              ...item,
              active:action.payload.id === item.id ? !item.active : item.active 
          }
      })
        state.reportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
        state.reportDepartmentDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(reportDepartmentChangeStatusACTION.pending, (state, action) => {
        //console.log("reportDepartmentChangeStatusACTION pending", action);
        state.reportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(reportDepartmentChangeStatusACTION.rejected, (state, action) => {
        //console.log("reportDepartmentChangeStatusACTION rejected", action);
        state.reportDepartmentDetails.apiMsg.message = action.error.message;
        state.reportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- reportDepartmentChangeStatusACTION end -------------------------

     //------------------------------- reportDepartmentDeleteACTION start -------------------------
    
  builder.addCase(reportDepartmentDeleteACTION.fulfilled, (state, action) => {
  //console.log("reportDepartmentDeleteACTION", action);

  state.reportDepartments.data.records = state.reportDepartments.data.records.filter((item,index)=>item.id !== action.payload.data.id)
  
  state.reportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
  state.reportDepartmentDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(reportDepartmentDeleteACTION.pending, (state, action) => {
    //console.log("reportDepartmentDeleteACTION pending", action);
    state.reportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(reportDepartmentDeleteACTION.rejected, (state, action) => {
    //console.log("reportDepartmentDeleteACTION rejected", action);
    state.reportDepartmentDetails.apiMsg.message = action.error.message;
    state.reportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
  }),
  //------------------------------- reportDepartmentDeleteACTION end -------------------------

  //------------------------------- getReportDepartmentACTION start -------------------------

  builder.addCase(getReportDepartmentACTION.fulfilled, (state, action) => {
    //console.log("getReportDepartmentACTION", action);
    state.selectedReportDepartmentDetails.data = action.payload.data;
    state.selectedReportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
    state.selectedReportDepartmentDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getReportDepartmentACTION.pending, (state, action) => {
    //console.log("getReportDepartmentACTION pending", action);
    state.selectedReportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getReportDepartmentACTION.rejected, (state, action) => {
    //console.log("getReportDepartmentACTION rejected", action);
    state.selectedReportDepartmentDetails.apiMsg.message = action.error.message;
    state.selectedReportDepartmentDetails.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getReportDepartmentACTION end -------------------------
    
  },
});

export const { RESET,ResetReportDepartmentDetailsState } = reportDepartmentReducer.actions;
export default reportDepartmentReducer.reducer;
