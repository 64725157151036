import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  printerSettingAddUpdateACTION,
  printerSettingGetAllACTION,
  printerSettingChangeStatusACTION,
  printerSettingDeleteACTION,
  getPrinterSettingACTION 
} from "../../actions/settings/printerSettingActions";

const INITIAL_STATE = {
  printerSettings: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
 printerSettingDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedPrinterSettingDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdatePrinterSettingDetails:{
    apiMsg:{
      message: null,
      status: null,
      type: null,
    },
  }
};

const printerSettingReducer = createSlice({
  name: "PrinterSetting",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetPrinterSettingDetailsState(state, action) {
      state.printerSettingDetails = INITIAL_STATE.printerSettingDetails;
      state.insertUpdatePrinterSettingDetails = INITIAL_STATE.insertUpdatePrinterSettingDetails;
      state.selectedPrinterSettingDetails=INITIAL_STATE.selectedPrinterSettingDetails
    },
  },
  extraReducers: (builder) => {
    //------------------------------- printerSettingAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(printerSettingAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("printerSettingAddUpdateACTION", action);

        //state.printerSettings.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.printerSettings.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.meta.arg;
            }
            else{
              return x;
            }
          })

          state.printerSettings.data.records=updatedRecord;
        }
        else{
          if(state.printerSettings.data.records.length){
            state.printerSettings.data.records.unshift(action.payload.data.data);
          }
          else{
            state.printerSettings.data.records.push(action.payload.data.data);
          }
        }

        state.insertUpdatePrinterSettingDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdatePrinterSettingDetails.apiMsg.message = action.payload.message.message;
    }),
    builder.addCase(printerSettingAddUpdateACTION.pending, (state, action) => {
      state.insertUpdatePrinterSettingDetails.apiMsg.status = action.meta.requestStatus;
    }),
    builder.addCase(printerSettingAddUpdateACTION.rejected, (state, action) => {
      state.insertUpdatePrinterSettingDetails.apiMsg.message = action.error.message;
      state.insertUpdatePrinterSettingDetails.apiMsg.status = action.meta.requestStatus;
    }),

      //------------------------------- printerSettingAddUpdateACTION start -------------------------

      //------------------------------- printerSettingGetAllACTION start -------------------------

      builder.addCase(printerSettingGetAllACTION.fulfilled, (state, action) => {
       // console.log("printerSettingGetAllACTION", action);

        state.printerSettings.data = action.payload.data;
        state.printerSettings.apiMsg.status = action.meta.requestStatus;
        state.printerSettings.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(printerSettingGetAllACTION.pending, (state, action) => {
        //console.log("printerSettingGetAllACTION pending", action);
        state.printerSettings.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(printerSettingGetAllACTION.rejected, (state, action) => {
        //console.log("printerSettingGetAllACTION rejected", action);
        state.printerSettings.apiMsg.message = action.error.message;
        state.printerSettings.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- printerSettingGetAllACTION end -------------------------

    //------------------------------- printerSettingChangeStatusACTION start -------------------------

    builder.addCase(printerSettingChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("printerSettingChangeStatusACTION", action);

        state.printerSettings.data.records = state.printerSettings.data.records.map((item)=>{

          return {
              ...item,
              active:action.payload.id === item.id ? !item.active : item.active 
          }
      })
        state.printerSettingDetails.apiMsg.status = action.meta.requestStatus;
        state.printerSettingDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(printerSettingChangeStatusACTION.pending, (state, action) => {
        //console.log("printerSettingChangeStatusACTION pending", action);
        state.printerSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(printerSettingChangeStatusACTION.rejected, (state, action) => {
        //console.log("printerSettingChangeStatusACTION rejected", action);
        state.printerSettingDetails.apiMsg.message = action.error.message;
        state.printerSettingDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- printerSettingChangeStatusACTION end -------------------------

     //------------------------------- printerSettingDeleteACTION start -------------------------
    
  builder.addCase(printerSettingDeleteACTION.fulfilled, (state, action) => {
  //console.log("printerSettingDeleteACTION", action);

  state.printerSettings.data.records = state.printerSettings.data.records.filter((item,index)=>item.id !== action.payload.data.id)
  
  state.printerSettingDetails.apiMsg.status = action.meta.requestStatus;
  state.printerSettingDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(printerSettingDeleteACTION.pending, (state, action) => {
    //console.log("printerSettingDeleteACTION pending", action);
    state.printerSettingDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(printerSettingDeleteACTION.rejected, (state, action) => {
    //console.log("printerSettingDeleteACTION rejected", action);
    state.printerSettingDetails.apiMsg.message = action.error.message;
    state.printerSettingDetails.apiMsg.status = action.meta.requestStatus;
  }),
  //------------------------------- printerSettingDeleteACTION end -------------------------

  //------------------------------- getPrinterSettingACTION start -------------------------

  builder.addCase(getPrinterSettingACTION.fulfilled, (state, action) => {
    //console.log("getPrinterSettingACTION", action);
    state.selectedPrinterSettingDetails.data = action.payload.data;
    state.selectedPrinterSettingDetails.apiMsg.status = action.meta.requestStatus;
    state.selectedPrinterSettingDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getPrinterSettingACTION.pending, (state, action) => {
    //console.log("getPrinterSettingACTION pending", action);
    state.selectedPrinterSettingDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getPrinterSettingACTION.rejected, (state, action) => {
    //console.log("getPrinterSettingACTION rejected", action);
    state.selectedPrinterSettingDetails.apiMsg.message = action.error.message;
    state.selectedPrinterSettingDetails.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getPrinterSettingACTION end -------------------------
    
  },
});

export const { RESET,ResetPrinterSettingDetailsState } = printerSettingReducer.actions;
export default printerSettingReducer.reducer;
