import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  addACTION,
  updateACTION,
  restaurantServiceGetallACTION,
  changeStatusACTION,
  parentServicesSelectACTION,
  getserviceACTION,
  deleteACTION,
  updateServiceChargeACTION
} from "../../actions/restaurantsserviceActions";
import { Enums } from '../../../helper/constants/emuns-const';

const INITIAL_STATE = {
  stepper: {
    id: 1
  },
  restaurantServices: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  restaurantServiceDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedServiceDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateRestaurantServiceDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  parentServicesSelect: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  updateServiceChargeDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const restaurantsserviceReducer = createSlice({
  name: "RestaurantsService",
  initialState: INITIAL_STATE,
  reducers: {
    NEXTPREIOUS(state, action) {
      state.stepper.id = action.payload
    },
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetRestaurantServiceDetailsState(state, action) {
      state.restaurantServiceDetails = INITIAL_STATE.restaurantServiceDetails;
      state.insertUpdateRestaurantServiceDetails = INITIAL_STATE.insertUpdateRestaurantServiceDetails;
      state.selectedServiceDetails = INITIAL_STATE.selectedServiceDetails;
      state.parentServicesSelect = INITIAL_STATE.parentServicesSelect;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- addACTION start -------------------------
    // eslint-disable-next-line      
    builder.addCase(addACTION.fulfilled, (state, action) => {
      //console.log("addACTION", action);

      //state.restaurantServices.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.restaurantServices.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            return action.payload.data.data;
          }
          else {
            return x;
          }
        })

        state.restaurantServices.data.records = updatedRecord;
      }
      else {
        if (state.restaurantServices.data.records.length) {
          state.restaurantServices.data.records.unshift(action.payload.data.data);
        }
        else {
          state.restaurantServices.data.records.push(action.payload.data.data);
        }
      }
      action.meta.arg.id = action.payload.data.data.id;
      state.selectedServiceDetails.data = action.meta.arg;
      state.selectedServiceDetails.apiMsg.status = action.meta.requestStatus;
      state.selectedServiceDetails.apiMsg.message = action.payload.message.message;

      state.insertUpdateRestaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateRestaurantServiceDetails.apiMsg.message =
        action.payload.message.message;
    }),
      builder.addCase(addACTION.pending, (state, action) => {
        //console.log("addACTION pending", action);
        state.insertUpdateRestaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addACTION.rejected, (state, action) => {
        //console.log("addACTION rejected", action);
        state.insertUpdateRestaurantServiceDetails.apiMsg.message = action.error.message;
        state.insertUpdateRestaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addACTION end -------------------------
      //------------------------------- deleteACTION start -------------------------

      builder.addCase(deleteACTION.fulfilled, (state, action) => {
        //console.log("deleteACTION", action);

        state.restaurantServices.data.records = state.restaurantServices.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
        state.restaurantServiceDetails.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(deleteACTION.pending, (state, action) => {
        //console.log("deleteACTION pending", action);
        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteACTION.rejected, (state, action) => {
        //console.log("deleteACTION rejected", action);
        state.restaurantServiceDetails.apiMsg.message = action.error.message;
        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- deleteACTION end -------------------------
      //------------------------------- restaurantServiceGetallACTION start -------------------------

      builder.addCase(restaurantServiceGetallACTION.fulfilled, (state, action) => {
        //console.log("restaurantServiceGetallACTION fulfilled", action);
        if (action.meta.arg.withDefault && action.payload.data.records && action.payload.data.records.length) {
          action.payload.data.records.unshift({
            active: true,
            id: null,
            name: 'Default',
            parentServiceID: null,
            parentServiceName: '',
            serviceCharge: 0,
            serviceType: null,
            serviceTypeName: 'Default',
            serviceTypeID: null

          });
        }


        state.restaurantServices.data = action.payload.data;
        state.restaurantServices.apiMsg.status = action.meta.requestStatus;
        state.restaurantServices.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(restaurantServiceGetallACTION.pending, (state, action) => {
        //console.log("restaurantServiceGetallACTION pending", action);
        state.restaurantServices.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(restaurantServiceGetallACTION.rejected, (state, action) => {
        //console.log("restaurantServiceGetallACTION rejected", action);
        state.restaurantServices.apiMsg.message = action.error.message;
        state.restaurantServices.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- restaurantServiceGetallACTION end -------------------------
      //------------------------------- changeStatusACTION start -------------------------

      builder.addCase(changeStatusACTION.fulfilled, (state, action) => {


        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.restaurantServices.data.records = state.restaurantServices.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
        state.restaurantServiceDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changeStatusACTION.pending, (state, action) => {
        //console.log("changeStatusACTION pending", action);
        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(changeStatusACTION.rejected, (state, action) => {
        //console.log("changeStatusACTION rejected", action);
        state.restaurantServiceDetails.apiMsg.message = action.error.message;
        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- changeStatusACTION end -------------------------
      //------------------------------- parentServicesSelectACTION start -------------------------

      builder.addCase(parentServicesSelectACTION.fulfilled, (state, action) => {
        //console.log("parentServicesSelectACTION", action);

        state.parentServicesSelect.data = action.payload.data.parentServicesSelect;
        state.parentServicesSelect.apiMsg.status = action.meta.requestStatus;
        state.parentServicesSelect.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(parentServicesSelectACTION.pending, (state, action) => {
        //console.log("parentServicesSelectACTION pending", action);
        state.restaurantServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(parentServicesSelectACTION.rejected, (state, action) => {
        //console.log("parentServicesSelectACTION rejected", action);
        state.parentServicesSelect.apiMsg.message = action.error.message;
        state.parentServicesSelect.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- parentServicesSelectACTION end -------------------------
      //------------------------------- getserviceACTION start -------------------------

      builder.addCase(getserviceACTION.fulfilled, (state, action) => {
        //console.log("getserviceACTION", action);

        state.selectedServiceDetails.data = action.payload.data;
        state.selectedServiceDetails.apiMsg.status = action.meta.requestStatus;
        state.selectedServiceDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getserviceACTION.pending, (state, action) => {
        //console.log("getserviceACTION pending", action);
        state.selectedServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getserviceACTION.rejected, (state, action) => {
        //console.log("getserviceACTION rejected", action);
        state.selectedServiceDetails.apiMsg.message = action.error.message;
        state.selectedServiceDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getserviceACTION end -------------------------

      //------------------------------- updateServiceChargeACTION start -------------------------

      builder.addCase(updateServiceChargeACTION.fulfilled, (state, action) => {
        //console.log("updateServiceChargeACTION", action);
        state.restaurantServices.data.records = state.restaurantServices.data.records.map((item) => {
          let updatedCharge = action.meta.arg.serviceCharges.filter((actionitem, index) => actionitem.id === item.id)[0].serviceCharge;
          return {
            ...item,
            serviceCharge: updatedCharge
          }
        });
        state.updateServiceChargeDetails.data = action.payload.data;
        state.updateServiceChargeDetails.apiMsg.status = action.meta.requestStatus;
        state.updateServiceChargeDetails.apiMsg.message = action.payload.message.message;
      }
      ),
      builder.addCase(updateServiceChargeACTION.pending, (state, action) => {
        //console.log("updateServiceChargeACTION pending", action);
        state.updateServiceChargeDetails.apiMsg.status = action.meta.requestStatus;
      }
      ),
      builder.addCase(updateServiceChargeACTION.rejected, (state, action) => {
        //console.log("updateServiceChargeACTION rejected", action);
        state.updateServiceChargeDetails.apiMsg.message = action.error.message;
        state.updateServiceChargeDetails.apiMsg.status = action.meta.requestStatus;
      }
      )

    //------------------------------- updaterestaurantdetailsACTION end -------------------------
  },
});

export const { NEXTPREIOUS, RESET, ResetRestaurantServiceDetailsState } = restaurantsserviceReducer.actions;
export default restaurantsserviceReducer.reducer;
