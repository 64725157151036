import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getAllCashInReportACTION, getCashInReportAction, updateCashInAction, CashInDeleteACTION } from "../../actions/cashInReportActions/cashInReport.Actions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    cashInReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCashIn: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    updateCashIn: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteCashIn: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const cashInReportReducer = createSlice({
    name: "Reports",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCashIneDetailsState(state, action) {
            state.selectedCashIn = INITIAL_STATE.selectedCashIn;
            state.updateCashIn = INITIAL_STATE.updateCashIn;
            state.deleteCashIn = INITIAL_STATE.deleteCashIn;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getAllCashInReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllCashInReportACTION.fulfilled, (state, action) => {

            state.cashInReport.data = action.payload.data;
            state.cashInReport.apiMsg.status = action.meta.requestStatus;
            state.cashInReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllCashInReportACTION.pending, (state, action) => {
                //console.log("getCashLiftReportACTION pending", action);
                state.cashInReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllCashInReportACTION.rejected, (state, action) => {
                //console.log("getAllCashInReportACTION rejected", action);
                state.cashInReport.apiMsg.message = action.error.message;
                state.cashInReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllCashInReportACTION end -------------------------


        builder.addCase(getCashInReportAction.fulfilled, (state, action) => {
            state.selectedCashIn.data = action.payload.data;
            state.selectedCashIn.apiMsg.status = action.meta.requestStatus;
            state.selectedCashIn.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCashInReportAction.pending, (state, action) => {
            state.selectedCashIn.data = null;
            state.selectedCashIn.apiMsg.message = action.meta.requestStatus;;
            state.selectedCashIn.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCashInReportAction.rejected, (state, action) => {
            state.selectedCashIn.apiMsg.message = action.error.message;
            state.selectedCashIn.apiMsg.status = action.meta.requestStatus;
        });



        builder.addCase(updateCashInAction.fulfilled,
            (state, action) => {

                state.updateCashIn.data = action.payload.data.data;
                state.updateCashIn.apiMsg.status = action.meta.requestStatus;
                state.updateCashIn.apiMsg.message = action.payload.message.message;

                // if (!action.payload.newRecord) {
                let updatedRecord = state.cashInReport.data.records.map(x => {
                    if (x.id == action.payload.data.data.id) {
                        return action.payload.data.data;
                    }
                    else {
                        return x;
                    }
                })

                state.cashInReport.data.records = updatedRecord;
                // }
                // else {
                //     if (state.device.data.records.length) {
                //         state.device.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //         state.device.data.records.push(action.payload.data.data);
                //     }
                // }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            updateCashInAction.pending,
            (state, action) => {
                state.updateCashIn.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            updateCashInAction.rejected,
            (state, action) => {

                state.updateCashIn.apiMsg.message = action.error.message;
                state.updateCashIn.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );



        builder.addCase(CashInDeleteACTION.fulfilled, (state, action) => {
            state.cashInReport.data.records = state.cashInReport.data.records.filter((item, index) => item.id !== action.payload.data.id)
            // console.log(action.meta.arg , "argssssssssss");
            state.deleteCashIn.apiMsg.status = action.meta.requestStatus;
            state.deleteCashIn.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(CashInDeleteACTION.pending, (state, action) => {
            state.deleteCashIn.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(CashInDeleteACTION.rejected, (state, action) => {
            state.deleteCashIn.apiMsg.message = action.error.message;
            state.deleteCashIn.apiMsg.status = action.meta.requestStatus;
        });


    }
});


export const { RESET, ResetCashIneDetailsState } = cashInReportReducer.actions;
export default cashInReportReducer.reducer;
