import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  mealCourseAddUpdateACTION,
  mealCourseGetAllACTION,
  mealCourseChangeStatusACTION,
  mealCourseDeleteACTION,
  getMealCourseACTION
} from "../../actions/mealCourseActions";
import { Enums } from '../../../helper/constants/emuns-const';

const INITIAL_STATE = {
  mealCourses: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  mealCourseDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateMealCourseDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const mealCourseReducer = createSlice({
  name: "MealCourse",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetMealCourseDetailsState(state, action) {
      state.mealCourseDetails = INITIAL_STATE.mealCourseDetails;
      state.insertUpdateMealCourseDetails = INITIAL_STATE.insertUpdateMealCourseDetails;
    },
  },
  extraReducers: (builder) => {

    //------------------------------- mealCourseAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(mealCourseAddUpdateACTION.fulfilled, (state, action) => {
      //console.log("mealCourseAddUpdateACTION", action);

      //state.mealCourses.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.mealCourses.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            return action.meta.arg;
          }
          else {
            return x;
          }
        })

        state.mealCourses.data.records = updatedRecord;
      }
      else {
        action.meta.arg.id = action.payload.data.data;
        if (state.mealCourses.data.records.length) {
          state.mealCourses.data.records.unshift(action.meta.arg);
        }
        else {
          state.mealCourses.data.records.push(action.meta.arg);
        }
      }

      state.insertUpdateMealCourseDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateMealCourseDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(mealCourseAddUpdateACTION.pending, (state, action) => {
        state.insertUpdateMealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(mealCourseAddUpdateACTION.rejected, (state, action) => {
        state.insertUpdateMealCourseDetails.apiMsg.message = action.error.message;
        state.insertUpdateMealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- mealCourseAddUpdateACTION end -------------------------

      //------------------------------- mealCourseGetAllACTION start -------------------------

      builder.addCase(mealCourseGetAllACTION.fulfilled, (state, action) => {
        //console.log("mealCourseGetAllACTION", action);

        state.mealCourses.data = action.payload.data;
        state.mealCourses.apiMsg.status = action.meta.requestStatus;
        state.mealCourses.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(mealCourseGetAllACTION.pending, (state, action) => {
        //console.log("mealCourseGetAllACTION pending", action);
        state.mealCourses.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(mealCourseGetAllACTION.rejected, (state, action) => {
        // console.log("mealCourseGetAllACTION rejected", action);
        state.mealCourses.apiMsg.message = action.error.message;
        state.mealCourses.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- mealCourseGetAllACTION end -------------------------

      //------------------------------- mealCourseChangeStatusACTION start -------------------------

      builder.addCase(mealCourseChangeStatusACTION.fulfilled, (state, action) => {

        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.mealCourses.data.records = state.mealCourses.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
        state.mealCourseDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(mealCourseChangeStatusACTION.pending, (state, action) => {
        // console.log("mealCourseChangeStatusACTION pending", action);
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(mealCourseChangeStatusACTION.rejected, (state, action) => {
        //console.log("mealCourseChangeStatusACTION rejected", action);
        state.mealCourseDetails.apiMsg.message = action.error.message;
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- mealCourseChangeStatusACTION end -------------------------

      //------------------------------- mealCourseDeleteACTION start -------------------------

      builder.addCase(mealCourseDeleteACTION.fulfilled, (state, action) => {
        //console.log("mealCourseDeleteACTION", action);

        state.mealCourses.data.records = state.mealCourses.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
        state.mealCourseDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(mealCourseDeleteACTION.pending, (state, action) => {
        //console.log("mealCourseDeleteACTION pending", action);
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(mealCourseDeleteACTION.rejected, (state, action) => {
        //console.log("mealCourseDeleteACTION rejected", action);
        state.mealCourseDetails.apiMsg.message = action.error.message;
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- mealCourseDeleteACTION end -------------------------
      //------------------------------- getMealCourseACTION start -------------------------

      builder.addCase(getMealCourseACTION.fulfilled, (state, action) => {
        //console.log("getMealCourseACTION", action);

        state.mealCourseDetails.data = action.payload.data;
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
        state.mealCourseDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getMealCourseACTION.pending, (state, action) => {
        // console.log("getMealCourseACTION pending", action);
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getMealCourseACTION.rejected, (state, action) => {
        //console.log("getMealCourseACTION rejected", action);
        state.mealCourseDetails.apiMsg.message = action.error.message;
        state.mealCourseDetails.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- getMealCourseACTION end -------------------------

  },
});

export const { RESET, ResetMealCourseDetailsState } = mealCourseReducer.actions;
export default mealCourseReducer.reducer;
