import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
    getPayInOutACTION,
    payInOutAddUpdateACTION,
    payInOutChangeStatusACTION,
    payInOutGetAllACTION,
    payInOutDeleteACTION
} from "../actions/payInOut.Actions";

const INITIAL_STATE = {
    payInOuts: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    payInOutDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatePayInOutDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const payInOutReducer = createSlice({
    name: "PayInOut",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPayInOutDetailsState(state, action) {
            state.payInOutDetails = INITIAL_STATE.payInOutDetails;
            state.insertUpdatePayInOutDetails = INITIAL_STATE.insertUpdatePayInOutDetails;
           
        },
    },
    extraReducers: (builder) => {

        //------------------------------- payInOutAddUpdateACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(payInOutAddUpdateACTION.fulfilled, (state, action) => {
            //console.log("payInOutAddUpdateACTION", action);

            if (!action.payload.newRecord) {
                let updatedRecord = state.payInOuts.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        return action.payload.data.data;
                    }
                    else {
                        return x;
                    }
                })

                state.payInOuts.data.records = updatedRecord;
            }
            else {
                if (state.payInOuts.data.records.length) {
                    state.payInOuts.data.records.unshift(action.payload.data.data);
                }
                else {
                    state.payInOuts.data.records.push(action.payload.data.data);
                }
            }

            state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            state.insertUpdatePayInOutDetails.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(payInOutAddUpdateACTION.pending, (state, action) => {
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(payInOutAddUpdateACTION.rejected, (state, action) => {
                state.insertUpdatePayInOutDetails.apiMsg.message = action.error.message;
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- payInOutAddUpdateACTION end -------------------------

            //------------------------------- payInOutGetAllACTION start -------------------------

            builder.addCase(payInOutGetAllACTION.fulfilled, (state, action) => {
                //console.log("payInOutGetAllACTION", action);

                state.payInOuts.data = action.payload.data;
                state.payInOuts.apiMsg.status = action.meta.requestStatus;
                state.payInOuts.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(payInOutGetAllACTION.pending, (state, action) => {
                //console.log("payInOutGetAllACTION pending", action);
                state.payInOuts.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(payInOutGetAllACTION.rejected, (state, action) => {
                // console.log("payInOutGetAllACTION rejected", action);
                state.payInOuts.apiMsg.message = action.error.message;
                state.payInOuts.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- payInOutGetAllACTION end -------------------------

            //------------------------------- payInOutChangeStatusACTION start -------------------------

            builder.addCase(payInOutChangeStatusACTION.fulfilled, (state, action) => {
                //console.log("payInOutChangeStatusACTION", action);

                state.payInOuts.data.records = state.payInOuts.data.records.map((item) => {

                    return {
                        ...item,
                        active: action.payload.id === item.id ? !item.active : item.active
                    }
                })
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePayInOutDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(payInOutChangeStatusACTION.pending, (state, action) => {
                // console.log("payInOutChangeStatusACTION pending", action);
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(payInOutChangeStatusACTION.rejected, (state, action) => {
                //console.log("payInOutChangeStatusACTION rejected", action);
                state.insertUpdatePayInOutDetails.apiMsg.message = action.error.message;
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- payInOutChangeStatusACTION end -------------------------

            //------------------------------- payInOutDeleteACTION start -------------------------

            builder.addCase(payInOutDeleteACTION.fulfilled, (state, action) => {
                //console.log("payInOutDeleteACTION", action);

                state.payInOuts.data.records = state.payInOuts.data.records.filter((item, index) => item.id !== action.payload.data.id)

                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePayInOutDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(payInOutDeleteACTION.pending, (state, action) => {
                //console.log("payInOutDeleteACTION pending", action);
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(payInOutDeleteACTION.rejected, (state, action) => {
                //console.log("payInOutDeleteACTION rejected", action);
                state.insertUpdatePayInOutDetails.apiMsg.message = action.error.message;
                state.insertUpdatePayInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- payInOutDeleteACTION end -------------------------
            //------------------------------- getPayInOutACTION start -------------------------

            builder.addCase(getPayInOutACTION.fulfilled, (state, action) => {
                //console.log("getPayInOutACTION", action);

                state.payInOutDetails.data = action.payload.data;
                state.payInOutDetails.apiMsg.status = action.meta.requestStatus;
                state.payInOutDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getPayInOutACTION.pending, (state, action) => {
                // console.log("getPayInOutACTION pending", action);
                state.payInOutDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getPayInOutACTION.rejected, (state, action) => {
                //console.log("getPayInOutACTION rejected", action);
                state.payInOutDetails.apiMsg.message = action.error.message;
                state.payInOutDetails.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getPayInOutACTION end -------------------------

    },
});

export const { RESET, ResetPayInOutDetailsState } = payInOutReducer.actions;
export default payInOutReducer.reducer;
