import yup from 'yup'

export const setLocalStore = (KEY,VAlUE)=>{

    try {
        // To store data
        localStorage.setItem(KEY, VAlUE);
        return 201
    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}

export const getLocalStore = (KEY)=>{

    try {
        // To retrieve data
        localStorage.getItem(KEY);
        return 200
    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}
export const clearLocalStore = (allclear,key)=>{

    if (allclear) {
        // To clear the whole data stored in localStorage
        localStorage.clear();
    }else{

        // To clear a specific item
        localStorage.removeItem(key);
    }
    

}
