import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";
import {Enums} from '../../../helper/constants/emuns-const';

export const updateACTION = createAsyncThunk(
  "RestaurantsService/update",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/restaurantservice/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addACTION = createAsyncThunk(
  "RestaurantsService/add",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/restaurantservice/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            ////console.log("response.data ", response.data);
            Toast.success(response.data.message.message);
            return {
              data:{
               ...response.data,
             },
             message:{
               code:response.data.message.code,
               type:response.data.message.type,
               message:response.data.message.message
             },
             newRecord:data.id ? false : true
             }
          } else {
            ////console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const deleteACTION = createAsyncThunk(
  "RestaurantsService/delete",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/restaurantservice/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const restaurantServiceGetallACTION = createAsyncThunk(
  "RestaurantsService/restaurantServiceGetall",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {
        ...data.filter
       },
      restaurantID: data.restaurantID,
    };

    return apiCall("/restaurantservice/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            ////console.log("response.data ", response.data);
            return {
              ...response.data,
              data: {
                ...response.data.data,
                parentServicesSelect: response.data.data.records.map(
                  (item, index) => {
                    return {
                      id: item.id,
                      name: item.name,
                      serviceType: item.serviceType,
                    };
                  }
                ),
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const parentServicesSelectACTION = createAsyncThunk(
  "RestaurantsService/parentServicesSelect",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {
       // serviceType: 3,
        onlyParentServices: true,
      },
      restaurantID: data,
    };

    return apiCall("/restaurantservice/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            return {
              ...response.data,
              data: {
                ...response.data.data,
                parentServicesSelect: response.data.data.records.map(
                  (item, index) => {
                    return {
                      id: item.id,
                      name: item.name,
                      serviceType: item.serviceType,
                    };
                  }
                ),
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const changeStatusACTION = createAsyncThunk(
  "RestaurantsService/changeStatus",
  async (data) => {
    return apiCall("/restaurantservice/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            if (data.statusType !== Enums.ChangeStatus.SortOrder) {
              Toast.success(response.data.message.message)
            }
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getserviceACTION = createAsyncThunk(
  "RestaurantsService/getservice",
  async (data) => {
    return apiCall("/restaurantservice/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            
            return {
              ...response.data,
             
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const updateServiceChargeACTION = createAsyncThunk(
  "RestaurantsService/updateservicecharge",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/restaurantservice/updateservicecharge", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            ////console.log("response.data ", response.data);
            Toast.success(response.data.message.message);
            return {
              data:{
               ...response.data,
             },
             message:{
               code:response.data.message.code,
               type:response.data.message.type,
               message:response.data.message.message
             }
             }
          } else {
            ////console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

