import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  promotionAddUpdateACTION,
  promotionGetAllACTION,
  promotionChangeStatusACTION,
  promotionDeleteACTION,
  getPromotionACTION
} from "../../../redux-store/actions/discountPromotions/promotions";
import _ from "lodash";

const INITIAL_STATE = {
  promotions: {
    data: {
      records: null
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  promotionDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdatePromotionUpdate: {
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  scheduleSpecificDayDetails: {
    data: {
      days: []
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  scheduleDayDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
};

const promotionReducer = createSlice({
  name: "promotion",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetPromotionDetailsState(state, action) {
      state.promotionDetails = INITIAL_STATE.promotionDetails;
      state.insertUpdatePromotionUpdate = INITIAL_STATE.insertUpdatePromotionUpdate;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- promotionAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(promotionAddUpdateACTION.fulfilled, (state, action) => {
      console.log("promotionAddUpdateACTION", action);

      //state.promotions.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.promotions.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            return action.payload.data.data;
          }
          else {
            return x;
          }
        })

        state.promotions.data.records = updatedRecord;
      }
      else {

        if (state.promotions.data.records.length) {
          state.promotions.data.records.unshift(action.payload.data.data);
        }
        else {
          state.promotions.data.records.push(action.payload.data.data);
        }
      }

      state.insertUpdatePromotionUpdate.apiMsg.status = action.meta.requestStatus;
      state.insertUpdatePromotionUpdate.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(promotionAddUpdateACTION.pending, (state, action) => {
        state.insertUpdatePromotionUpdate.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(promotionAddUpdateACTION.rejected, (state, action) => {
        state.insertUpdatePromotionUpdate.apiMsg.message = action.error.message;
        state.insertUpdatePromotionUpdate.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- promotionAddUpdateACTION start -------------------------

      //------------------------------- promotionGetAllACTION start -------------------------

      builder.addCase(promotionGetAllACTION.fulfilled, (state, action) => {
        //console.log("promotionGetAllACTION", action);

        state.promotions.data.records = action.payload.data.records;
        state.promotions.apiMsg.status = action.meta.requestStatus;
        state.promotions.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(promotionGetAllACTION.pending, (state, action) => {
        //console.log("promotionGetAllACTION pending", action);
        state.promotions.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(promotionGetAllACTION.rejected, (state, action) => {
        //console.log("promotionGetAllACTION rejected", action);
        state.promotions.apiMsg.message = action.error.message;
        state.promotions.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- promotionGetAllACTION end -------------------------

      //------------------------------- promotionChangeStatusACTION start -------------------------

      builder.addCase(promotionChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("promotionChangeStatusACTION", action);

        state.promotions.data.records = state.promotions.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id ? !item.active : item.active
          }
        })
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
        state.promotionDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(promotionChangeStatusACTION.pending, (state, action) => {
        //console.log("promotionChangeStatusACTION pending", action);
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(promotionChangeStatusACTION.rejected, (state, action) => {
        //console.log("promotionChangeStatusACTION rejected", action);
        state.promotionDetails.apiMsg.message = action.error.message;
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- promotionChangeStatusACTION end -------------------------

      //------------------------------- promotionDeleteACTION start -------------------------

      builder.addCase(promotionDeleteACTION.fulfilled, (state, action) => {
        //console.log("promotionDeleteACTION", action);

        state.promotions.data.records = state.promotions.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
        state.promotionDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(promotionDeleteACTION.pending, (state, action) => {
        //console.log("promotionDeleteACTION pending", action);
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(promotionDeleteACTION.rejected, (state, action) => {
        //console.log("promotionDeleteACTION rejected", action);
        state.promotionDetails.apiMsg.message = action.error.message;
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- promotionDeleteACTION end -------------------------

      //------------------------------- getPromotionACTION start -------------------------

      builder.addCase(getPromotionACTION.fulfilled, (state, action) => {
        //console.log("getPromotionACTION", action);
        state.promotionDetails.data = action.payload.data;
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
        state.promotionDetails.apiMsg.message = action.payload.message.message;
        state.scheduleDayDetails.data = state.promotionDetails.data.promotionDays;
        state.scheduleSpecificDayDetails = state.promotionDetails.data.promotionDates;
      }),
      builder.addCase(getPromotionACTION.pending, (state, action) => {
        //console.log("getPromotionACTION pending", action);
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getPromotionACTION.rejected, (state, action) => {
        //console.log("getPromotionACTION rejected", action);
        state.promotionDetails.apiMsg.message = action.error.message;
        state.promotionDetails.apiMsg.status = action.meta.requestStatus;
      })

    //------------------------------- getPromotionACTION end -------------------------

  },
});

export const { RESET, ResetPromotionDetailsState } = promotionReducer.actions;
export default promotionReducer.reducer;
