import { createSlice } from "@reduxjs/toolkit";
import { getAllRestaurantTableAction, getRestaurantTableAction, insertUpdateRestaurantTableAction, restautantTableChangeStatusACTION, restautantTableDeleteACTION, restaurantServiceGetallACTION } from "../../actions/settings/restuarantTableAction";
import { toast } from 'react-toastify';
import { Enums } from '../../../helper/constants/emuns-const';

const INITIAL_STATE = {

    restuarantTable: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedRestuarant: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    restaurantServices: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};



const restaurantTableReducer = createSlice({
    name: "Restuarant Table",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetRestuarantTableDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedRestuarant = INITIAL_STATE.selectedRestuarant;
        },
    },

    extraReducers: (builder) => {

        //getAllRestaurantTableAction start

        builder.addCase(getAllRestaurantTableAction.fulfilled, (state, action) => {
            state.restuarantTable.data = action.payload.data;
            state.restuarantTable.apiMsg.status = action.meta.requestStatus;
            state.restuarantTable.apiMsg.message = "success";
        });
        builder.addCase(getAllRestaurantTableAction.pending, (state, action) => {
            state.restuarantTable.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllRestaurantTableAction.rejected, (state, action) => {
            state.restuarantTable.apiMsg.message = action.error.message;
            state.restuarantTable.apiMsg.status = action.meta.requestStatus;
        });

        // getAllRestaurantTableAction end

        // insertUpdateRestaurantTableAction start

        builder.addCase(
            insertUpdateRestaurantTableAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.restuarantTable.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.restuarantTable.data.records = updatedRecord;
                }
                else {
                    if (state.restuarantTable.data.records.length) {
                        state.restuarantTable.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.restuarantTable.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateRestaurantTableAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRestaurantTableAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateRestaurantTableAction end

        //------------------------------- getRestaurantTableAction start -------------------------

        builder.addCase(getRestaurantTableAction.fulfilled, (state, action) => {
            state.selectedRestuarant.data = action.payload.data;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
            state.selectedRestuarant.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getRestaurantTableAction.pending, (state, action) => {
            state.selectedRestuarant.data = null;
            state.selectedRestuarant.apiMsg.message = action.meta.requestStatus;;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getRestaurantTableAction.rejected, (state, action) => {
            state.selectedRestuarant.apiMsg.message = action.error.message;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
        });


        //  getRestaurantTableAction end



        // restautantTableChangeStatusACTION start


        builder.addCase(restautantTableChangeStatusACTION.fulfilled, (state, action) => {
            var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
            state.restuarantTable.data.records = state.restuarantTable.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
                    sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restautantTableChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restautantTableChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // restautantTableChangeStatusACTION end



        //------------------------------- restautantTableDeleteACTION start -------------------------

        builder.addCase(restautantTableDeleteACTION.fulfilled, (state, action) => {

            state.restuarantTable.data.records = state.restuarantTable.data.records.filter((item, index) => item.id !== action.payload.data.id)
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restautantTableDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restautantTableDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restautantTableDeleteACTION end -------------------------


        //------------------------------- restaurantServiceGetallACTION start -------------------------

        builder.addCase(restaurantServiceGetallACTION.fulfilled, (state, action) => {
            //console.log("restaurantServiceGetallACTION fulfilled", action);
            if (action.meta.arg.withDefault && action.payload.data.records && action.payload.data.records.length) {
                action.payload.data.records.unshift({
                    active: true,
                    id: null,
                    name: 'Default',
                    parentServiceID: null,
                    parentServiceName: '',
                    serviceCharge: 0,
                    serviceType: null,
                    serviceTypeName: 'Default',
                    serviceTypeID: null

                });
            }


            state.restaurantServices.data = action.payload.data;
            state.restaurantServices.apiMsg.status = action.meta.requestStatus;
            state.restaurantServices.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restaurantServiceGetallACTION.pending, (state, action) => {
            //console.log("restaurantServiceGetallACTION pending", action);
            state.restaurantServices.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantServiceGetallACTION.rejected, (state, action) => {
            //console.log("restaurantServiceGetallACTION rejected", action);
            state.restaurantServices.apiMsg.message = action.error.message;
            state.restaurantServices.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restaurantServiceGetallACTION end -------------------------




    }




});

export const { RESET, ResetRestuarantTableDetailsState } = restaurantTableReducer.actions;

export default restaurantTableReducer.reducer;