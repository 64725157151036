import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../../API';
import * as Toast from "../../../component/Toast";


export const getAllUserShiftReportACTION = createAsyncThunk(
    'Reports/getallUserShiftReport',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                restaurantDeviceID: data.restaurantDeviceID ? data.restaurantDeviceID : null,
                posUserID: data.posUserID ? data.posUserID : null,
                fromDate: data.fromDate,
                toDate: data.toDate,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/getallposusershiftdetail', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)