import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { getWebContentAction, insertUpdateWebContentAction, getCookiePolicyAction, getPrivacyPolicyAction, getTermsConditionAction } from "../actions/webContentActions";

const INITIAL_STATE = {


    cookiePolicy: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    privacyPolicy: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    termscondition: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateWebContent: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const webContentReducer = createSlice({
    name: "WebContent",
    initialState: INITIAL_STATE,
    reducers: {
        ResetWebContent(state, action) {
            state.cookiePolicy = INITIAL_STATE.cookiePolicy
            state.privacyPolicy = INITIAL_STATE.privacyPolicy
            state.termscondition = INITIAL_STATE.termscondition

            state.insertUpdateWebContent = INITIAL_STATE.insertUpdateWebContent
        }
    },
    extraReducers: (builder) => {






        //------------------------------- getCookiePolicyAction start -------------------------

        builder.addCase(getCookiePolicyAction.fulfilled, (state, action) => {

            state.cookiePolicy.data = action.payload.data;
            state.cookiePolicy.apiMsg.status = action.meta.requestStatus;
            state.cookiePolicy.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCookiePolicyAction.pending, (state, action) => {

            state.cookiePolicy.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCookiePolicyAction.rejected, (state, action) => {

            state.cookiePolicy.apiMsg.message = action.error.message;
            state.cookiePolicy.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getCookiePolicyAction end -------------------------



        //------------------------------- getPrivacyPolicyAction start -------------------------

        builder.addCase(getPrivacyPolicyAction.fulfilled, (state, action) => {

            state.privacyPolicy.data = action.payload.data;
            state.privacyPolicy.apiMsg.status = action.meta.requestStatus;
            state.privacyPolicy.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getPrivacyPolicyAction.pending, (state, action) => {

            state.privacyPolicy.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPrivacyPolicyAction.rejected, (state, action) => {

            state.privacyPolicy.apiMsg.message = action.error.message;
            state.privacyPolicy.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getPrivacyPolicyAction end -------------------------



        //------------------------------- getTermsConditionAction start -------------------------

        builder.addCase(getTermsConditionAction.fulfilled, (state, action) => {

            state.termscondition.data = action.payload.data;
            state.termscondition.apiMsg.status = action.meta.requestStatus;
            state.termscondition.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getTermsConditionAction.pending, (state, action) => {

            state.termscondition.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getTermsConditionAction.rejected, (state, action) => {

            state.termscondition.apiMsg.message = action.error.message;
            state.termscondition.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getTermsConditionAction end -------------------------


        //------------------------------- insertUpdateWebContentAction start -------------------------
        builder.addCase(insertUpdateWebContentAction.fulfilled, (state, action) => {


            state.insertUpdateWebContent.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateWebContent.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateWebContentAction.pending, (state, action) => {

            state.insertUpdateWebContent.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateWebContentAction.rejected, (state, action) => {

            state.insertUpdateWebContent.apiMsg.message = action.error.message;
            state.insertUpdateWebContent.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateWebContentAction end -------------------------

    }
});

export const { ResetWebContent } = webContentReducer.actions;
export default webContentReducer.reducer;