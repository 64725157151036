import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";

export const getAllDeviceAction = createAsyncThunk(
    "Device/GetAllDevice",
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                ...data.filter
            },
            restaurantID: data.id,
        };

        return apiCall("/restaurant/device/getall", "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getDeviceAction = createAsyncThunk(
    "Device/GetDevice",
    async (data) => {
        return apiCall("/restaurant/device/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateDeviceAction = createAsyncThunk(
    "Device/insertupdateDevice",
    async (data) => {

        return apiCall("/restaurant/device/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const deviceChangeStatusACTION = createAsyncThunk(
    "Device/changeStatus",
    async (data) => {
        return apiCall("/restaurant/device/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const deviceDeleteACTION = createAsyncThunk(
    "Device/delete",
    async (data) => {
        return apiCall("/restaurant/device/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getDeviceSettingAction = createAsyncThunk(
    "Device/GetDeviceSetting",
    async (data) => {
        return apiCall("/restaurant/devicesetting/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateDeviceSettingAction = createAsyncThunk(
    "Device/insertupdateDeviceSetting",
    async (data) => {

        return apiCall("/restaurant/devicesetting/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);