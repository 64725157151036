import { createSlice } from "@reduxjs/toolkit";
import { PosGeneralSettingsInsertUpdateACTION, getPosGeneralSettingsACTION } from "../../actions/settings/PosGeneralSettingsAction";

const INITIAL_STATE = {

    posGeneralSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatePosGeneralSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


};
const posGeneralSettingReducer = createSlice({
    name: "PosGeneralSetting",
    initialState: INITIAL_STATE,
    reducers: {
        ResetPosGeneralSetting(state, action) {
            state.posGeneralSetting = INITIAL_STATE.posGeneralSetting
            state.insertUpdatePosGeneralSetting = INITIAL_STATE.insertUpdatePosGeneralSetting

        }
    },
    extraReducers: (builder) => {
        //------------------------------- getPosGeneralSettingsACTION start -------------------------

        // builder.addCase(getPosGeneralSettingsACTION.fulfilled, (state, action) => {

        //     state.posGeneralSetting.data = action.payload.data;
        //     state.posGeneralSetting.apiMsg.status = action.meta.requestStatus;
        //     state.posGeneralSetting.apiMsg.message = action.payload.message.message;

        // });

        // builder.addCase(getPosGeneralSettingsACTION.pending, (state, action) => {

        //     state.posGeneralSetting.apiMsg.status = action.meta.requestStatus;

        // });

        // builder.addCase(getPosGeneralSettingsACTION.rejected, (state, action) => {

        //     state.posGeneralSetting.apiMsg.message = action.error.message;
        //     state.posGeneralSetting.apiMsg.status = action.meta.requestStatus;
        // });

        // //------------------------------- getPosGeneralSettingsACTION end -------------------------


        // //------------------------------- PosGeneralSettingsInsertUpdateACTION start -------------------------


        // builder.addCase(PosGeneralSettingsInsertUpdateACTION.fulfilled, (state, action) => {


        //     state.insertUpdatePosGeneralSetting.apiMsg.status = action.meta.requestStatus;
        //     state.insertUpdatePosGeneralSetting.apiMsg.message = action.payload.message.message;
        // });
        // builder.addCase(PosGeneralSettingsInsertUpdateACTION.pending, (state, action) => {

        //     state.insertUpdatePosGeneralSetting.apiMsg.status = action.meta.requestStatus;
        // });
        // builder.addCase(PosGeneralSettingsInsertUpdateACTION.rejected, (state, action) => {

        //     state.insertUpdatePosGeneralSetting.apiMsg.message = action.error.message;
        //     state.insertUpdatePosGeneralSetting.apiMsg.status = action.meta.requestStatus;
        // });
        //------------------------------- PosGeneralSettingsInsertUpdateACTION end -------------------------

    }
});

export const { ResetPosGeneralSetting } = posGeneralSettingReducer.actions;
export default posGeneralSettingReducer.reducer;
